import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pradeep-kumar-v',
  templateUrl: './pradeep-kumar-v.component.html',
  styleUrls: ['./pradeep-kumar-v.component.scss']
})
export class PradeepKumarVComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
