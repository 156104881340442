<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Terms & Conditions Area -->
<div class="terms-conditions-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <p><strong>Effective Date: 27/01/2022</strong></p>
            <p><strong>Site Covered: 27/01/2022</strong></p>
            <p><strong>&nbsp;</strong></p>
            
            <p><strong>THE AGREEMENT:</strong> The use of this website and services on this website provided by Compliance Inbox (hereinafter referred to as &ldquo;We&rdquo;, &ldquo;Us&rdquo;, &ldquo;Our&rdquo; , &ldquo;Website&rdquo;) are subject to the following Terms &amp; Conditions (hereinafter the &ldquo;Agreement&rdquo;), all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as &ldquo;Website&rdquo;) and any services provided by or on this Website (&ldquo;Services&rdquo;).</p>
            <p>&nbsp;</p>
            
            <p><strong>1) DEFINITIONS</strong></p>
            <p>The parties referred to in this Agreement shall be defined as follows:</p>
            <ol>
            <li>We, Us, Our: Compliance Inbox, as the creator, operator, and publisher of the Website, makes the Website, and certain Services on it, available to users. We, Us, Our, Ours and other first-person pronouns will refer to the Compliance Inbox, as well as all employees and affiliates of the Compliance Inbox.</li>
            <li>You, the User, the Client: You, as the user of the Website, will be referred to throughout this Agreement with second-person pronouns such as You, Your, Yours, or as User or Client.</li>
            <li>Parties: Collectively, the parties to this Agreement (Compliance Inbox and You) will be referred to as Parties.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>2) ASSENT &amp; ACCEPTANCE</strong></p>
            <p>By using the Website, You warrant that You have read and reviewed this Agreement and that You agree to be bound by it. If You do not agree to be bound by this Agreement, please leave the Website immediately. We only agree to provide use of this Website and Services to You if You assent to this Agreement.</p>
            <p>&nbsp;</p>
            <p><strong>3) AGE RESTRICTION</strong></p>
            <p>You must be at least 18 (eighteen) years of age to use this website or any Services contained herein. By using this Website, You represent and warrant that You are at least 18 years of age and may legally agree to this Agreement. We assume no responsibility or liability for any misrepresentation of Your age.</p>
            <p>&nbsp;</p>
            <p><strong>4) LICENSE TO USE WEBSITE</strong></p>
            <p>We may provide You with certain information as a result of Your use of the Website or Services. Such information may include, but is not limited to, documentation, data, or information developed by us and other materials which may assist in Your use of the Website or Services (&ldquo;Materials&rdquo;). Subject to this Agreement, We grant You a non-exclusive, limited, non-transferable and revocable license to use our Materials solely in connection with Your use of the Website and Services. Our Materials may not be used for any other purpose, and this license terminates upon Your cessation of use of the Website or Services or at the termination of this Agreement.</p>
            <p>&nbsp;</p>
            <p><strong>5) INTELLECTUAL PROPERTY</strong></p>
            <p>You agree that the Website and all Services provided by us are the property of BA Fintech Private Limited, owner of the management tool&rsquo; Compliance Inbox&rsquo;, including all copyrights, trademarks, trade secrets, patents, and other intellectual property (&ldquo;IP&rdquo;). You agree that we own all rights, title and interest in and to the IP and that You will not use our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), without express written permission from us.</p>
            <p>&nbsp;</p>
            <p><strong>6) USER OBLIGATIONS</strong></p>
            <p>As a user of the Website or Services, You may be asked to register with Us. When You do so, You will choose a user identifier, which may be Your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, Your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable You to use the Website and Services. You must not share such identifying information with any third party, and if You discover that Your identifying information has been compromised, You agree to notify Us immediately in writing. Email notification will suffice. You are responsible for maintaining the safety and security of Your identifying information as well as keeping Us apprised of any changes to Your identifying information. Providing false or inaccurate information, or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.</p>
            <p>&nbsp;</p>
            <p><strong>7) ACCEPTABLE USE</strong></p>
            <p>You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of us. You further agree not to use the Website or Services:</p>
            <p>&nbsp;</p>
            <ol>
            <li>To harass, abuse, or threaten others or otherwise violate any person&rsquo;s legal rights.</li>
            </ol>
            <ol>
            <li>To violate any intellectual property rights of ours or any third party;</li>
            </ol>
            <ol>
            <li>To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;</li>
            </ol>
            <ol>
            <li>To perpetrate any fraud;</li>
            </ol>
            <ol>
            <li>To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</li>
            </ol>
            <ol>
            <li>To publish or distribute any obscene or defamatory material;</li>
            </ol>
            <ol>
            <li>To publish or distribute any material that incites violence, hate, or discrimination towards any group;</li>
            </ol>
           <ol>
            <li>To unlawfully gather information about others.</li>
            </ol>
            <p><strong>8) REVERSE ENGINEERING &amp; SECURITY</strong></p>
            <p>You agree not to undertake any of the following actions:</p>
            <ol>
            <li>Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;</li>
            <li>Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>9) DATA LOSS</strong></p>
            <p>We do not accept responsibility for the security of Your account or content. You agree that Your use of the Website or Services is at Your own risk.</p>
            <p>&nbsp;</p>
            <p><strong>10) INDEMNIFICATION</strong></p>
            <p>You agree to defend and indemnify the Compliance Inbox and any of its affiliates (if applicable) and hold Us harmless against any and all legal claims and demands, including reasonable attorney&rsquo;s fees, which may arise from or relate to Your use or misuse of the Website or Services, Your breach of this Agreement, or Your conduct or actions. You agree that we shall be able to select its own legal counsel and may participate in its own defense, if we wish.</p>
            <p>&nbsp;</p>
            <p><strong>11) SPAM POLICY</strong></p>
            <p>You are strictly prohibited from using the Website or any of our Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.</p>
            <p>&nbsp;</p>
            <p><strong>12) THIRD-PARTY LINKS &amp; CONTENT</strong></p>
            <p>We may occasionally post links to third party websites or other services. You agree that we are not responsible or liable for any loss or damage caused as a result of Your use of any third party services linked to from Our Website.</p>
            <p>&nbsp;</p>
            <p><strong>13) MODIFICATION &amp; VARIATION</strong></p>
            <p>We may, from time to time and at any time without notice to You, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement, unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.</p>
            <ol>
            <li>To the extent any part or sub-part of this Agreement is held ineffective or invalid by any court of law, You agree that the prior, effective version of this Agreement shall be considered enforceable and valid to the fullest extent.</li>
            <li>You agree to routinely monitor this Agreement and refer to the Effective Date posted at the top of this Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of this Agreement. You agree that Your continued use of the Website after any modifications to this Agreement is a manifestation of Your continued assent to this Agreement.</li>
            <li>In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>14) ENTIRE AGREEMENT</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p>This Agreement constitutes the entire understanding between the Parties with respect to any and all use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.</p>
            <p>&nbsp;</p>
            <p><strong>15) SERVICE INTERRUPTIONS</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p>We may need to interrupt Your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that Your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but we shall have no liability for any damage or loss caused as a result of such downtime.</p>
            <p>&nbsp;</p>
            <p><strong>16) TERM, TERMINATION &amp; SUSPENSION</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p>We may terminate this Agreement with You at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if You violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of us or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If You have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.</p>
            <p>&nbsp;</p>
            <p><strong>17) NO WARRANTIES</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p>You agree that Your use of the Website and Services is at Your sole and exclusive risk and that any Services provided by Us are on an &ldquo;As Is&rdquo; basis. We hereby expressly disclaim any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet Your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to You, through Your computer system, or as a result of loss of Your data from Your use of the Website or Services is Your sole responsibility and that we are not liable for any such damage or loss.</p>
            <p>&nbsp;</p>
            <p><strong>18) GENERAL PROVISIONS:</strong></p>
            <ol>
            <li><strong>LANGUAGE:</strong> All communications made or notices given pursuant to this Agreement shall be in the English language.</li>
            </ol>
            <ol>
            <li><strong>JURISDICTION, VENUE &amp; CHOICE OF LAW:</strong> Through Your use of the Website or Services, You agree that the laws of the India shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between You and us, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of the local courts of the following county: India. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine.</li>
            </ol>
            <ol>
            <li><strong>ARBITRATION</strong>: In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration. The arbitration shall be conducted in the following county: India. The arbitration shall be conducted by a single arbitrator, and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by applicable and governing laws of the following state: Maharashtra. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims, claims based on state law, and claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by us will not be subject to arbitration and may, as an exception to this subpart, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims.</li>
            </ol>
            <ol>
            <li><strong>ASSIGNMENT:</strong> This Agreement, or the rights granted hereunder, may not be assigned, sold, leased or otherwise transferred in whole or part by You. Should this Agreement, or the rights granted hereunder, by assigned, sold, leased or otherwise transferred by us, the rights and liabilities of us will bind and inure to any assignees, administrators, successors, and executors.</li>
            </ol>
            <ol>
            <li><strong>SEVERABILITY:</strong> If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such a condition, the remainder of this Agreement shall continue in full force.</li>
            </ol>
            <ol>
            <li><strong>NO WAIVER:</strong> In the event that We fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.</li>
            </ol>
            <ol>
            <li><strong>HEADINGS FOR CONVENIENCE ONLY:</strong> Headings of parts and subparts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.</li>
            </ol>
            <ol>
            <li><strong>NO AGENCY, PARTNERSHIP OR JOINT VENTURE:</strong> No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties.</li>
            </ol>
            <ol>
            <li><strong>FORCE MAJEURE:</strong> We are not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances.</li>
            </ol>
            <ol>
            <li><strong>ELECTRONIC COMMUNICATIONS PERMITTED:</strong> Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please email Us at the following address: <a href="mailto:info@complianceinbox.com">info@complianceinbox.com</a> .</li>
            </ol>
            <p>&nbsp;</p>
        </div>
    </div>
</div>
<!-- Terms & Conditions Area End -->