import { Component, OnInit } from '@angular/core';
import { CasestudyService } from 'src/app/services/casestudy.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  result:any;
  casestudyList:any;
  p: number = 1;
  pagination: number = 1;

  constructor(
    private _casestudyService: CasestudyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private titleService: Title, 
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.getCaseStudyList();

    this.titleService.setTitle('Case Studies| CiB Best Compliance and Risk Management Software');
    this.metaTagService.updateTag( { name:'keywords', content:'compliance and risk management'},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'CiB is the best Compliance and Risk Management Software with multiple case studies of happy and satisfied clientele'},"name='description'");
    
  }

  getCaseStudyList() {
    const formData = new FormData();
    formData.append("api_token", "Comp@ibx!#");

    this._casestudyService.casestudyData(formData).subscribe(res => {
      this.result = res;
      this.casestudyList = this.result.data;
      console.log(this.casestudyList);
    })
  }

  trackByFn(index:any, item:any) {
    return index; // or item.id
  }

  caseDetails(id:any, title:any) {
    debugger;
    let slug = title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      //.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');

     // console.log(slug);return false;
    this.router.navigate(['/case-study',  slug], { relativeTo: this._activatedRoute })
  }

}
