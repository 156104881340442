<style>
    .form-container {
  width: 70%; /* Adjust the width as needed */
  margin: 0 auto; /* Center align the form horizontally */
  background-color: #f0f0f0;
padding: 25px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block; /* Ensure labels are displayed in block format */
  /* font-weight: bold; */
}

.form-control {
  width: 100%; /* Full width input boxes */
}
.btn-container {
        text-align: center;
    }
    .button{
        width: 210px;
    }

</style>
 <!-- Inner Banner -->
<div class="inner-banner inner-bg5">
  <div class="container">
      <div class="inner-title text-center">
          <h3>DSAR</h3>
          <!-- <ul>
              <li><a routerLink="/">Home</a></li>
              <li><i class='bx bxs-chevron-right'></i></li>
              <li>Privacy Policy</li>
          </ul> -->
      </div>
  </div>
</div>
<!-- Inner Banner End -->

    
<div class="container py-5">
	<div class="row mb-3">
		<div class="col-lg-8 mx-auto text-center">
			<div class="lc-block mb-4">
        <div editable="rich">
            <p class="badge bg-dark mb-0">DSAR</p>
            <h1>DSAR (data subject access request)</h1>
            <!-- <img src="assets\img\NewIcon\dsarimage.png" alt="Image Description"> -->
        </div>
    </div>
    <!-- D:\CIBNEW\CiBNew\CiB2\CiB\dist\gloz-ng\assets\img\NewIcon\dsarimage.png -->
			<!-- /lc-block -->


			<div class="lc-block">
				<div editable="rich">

					<!-- <p class="lead"> DSAR (data subject access request)</p> -->

				</div>
			</div>
			<!-- /lc-block -->
		</div><!-- /col -->
	</div>

<!-- Inner Banner End -->
<p>&nbsp;</p>
<p>&nbsp;</p>
<div class="form-container">
    <form #dsarForm="ngForm" (ngSubmit)="submitForm(dsarForm.value)">
      <!-- Website Input -->
      <div class="form-group">
        <label for="website">Website:</label>
        <input type="url" class="form-control" id="websiteUrl" name="website" value="https://complianceinbox.com/" disabled>
      </div>
  
      <!-- Your Name Input -->
      <div class="form-group">
        <label for="name">Your Name :</label>
        <input type="text" class="form-control" id="userName" ngModel name="userName" placeholder="Enter Your Name">
      </div>
  
      <!-- Email Input -->
      <div class="form-group">
        <label for="email">What email address do you use to access the above website/portal</label>
        <input type="email" ngModel class="form-control" id="email" name="emailAddress" placeholder="Enter Your Email">
      </div>
  
      <!-- Submitting Request Options -->
      <div class="form-group">
        <label for="requestOption">You are submitting this request as</label>
        <div class="form-check">
          <input type="radio" class="form-check-input" ngModel id="option1" name="requestAs" value="option1">
          <label class="form-check-label" for="option1">The person or the parent/guardian of the person, whose name appears above</label>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input" id="option2"ngModel  name="requestAs" value="option2">
          <label class="form-check-label" for="option2">An agent authorized by the consumer to make this request on their behalf</label>
        </div>
      </div>
  
      <!-- Dropdown - CCPA/Other -->
      <div class="form-group">
        <label for="ccpaDropdown">CCPA/Other:</label>
        <select class="form-control" ngModel id="ccpaDropdown" name="ccpaOthers">
          <option value="ccpa">I am submitting request to</option>
          <option value="other1">Know what information is being collected from me</option>
          <option value="other2">Have my information deleted</option>
          <option value="other3">Access my personal information</option>
          <option value="other4">Fix Inaccurate information</option>
          <option value="other5">Receive a copy of my personal information</option>
          <option value="other4">Opt out of having my data shared for cross-context behavioral advertising</option>
          <option value="other6">Limit the use and disclosure of my sensitive personal information</option>
          <option value="other7"> Other (Please specify in comment box below)</option>
         
          
          
        </select>
      </div>
  <!-- Additional Comments -->
  <div class="form-group">
    <label for="additionalComments">Additional Comments:</label>
    <textarea class="form-control" ngModel id="additionalComments" name="comment" rows="4" placeholder="Enter your additional comments here"></textarea>
  </div>
   <!-- Confirm Checkbox -->
   <div class="form-check">
    <input type="checkbox" ngModel class="form-check-input" id="confirmCheck" name="confirmed" required>
    <label class="form-check-label" for="confirmCheck">I Confirm that Under penalty of perjury, I declare all the above information to be true and accurate.</label>
  </div>
  <p>&nbsp;</p>
      <!-- Submit Button -->
      <div class="btn-container">
        <button type="submit" class="btn btn-primary button">Submit</button>
    </div>
    </form>
  </div>
  <p>&nbsp;</p>
  