<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Refund and Cancellation Policy</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Refund and Cancellation Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Privacy Policy Area -->
<div class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <p>Welcome to Compliance Inbox!</p>
            <p>Please read the Refund and Cancellation Policy very carefully. We reserve the right to reject or cancel your request for any reason, including errors or omissions in the information you provide to us. If we do so after payment has been processed, we will issue a refund to you in the amount of the purchase price..You are quoted due to unforeseen circumstances. For any questions, concerns or disputes, you agree to contact us in a timely manner at the following: <a href="mailto:info@complianceinbox.com">info@complianceinbox.com</a>.</p>
            <p>&nbsp;</p>
            <p><strong>REFUNDS</strong></p>
            <ul>
            <li><strong>A. Refund method</strong></li>
            </ul>
            <p style="margin-left: 4%;">The refund will be initiated to your original payment account per your request.</p>
            <ul>
            <li><strong>B. Refund process time</strong></li>
            </ul>
            <p style="margin-left: 4%;">Refunds will be initiated within 72 hours from the Compliance Inbox side upon receipt of your complaint.</p>
            <p style="margin-left: 4%;">If you choose an <strong>Original Payment Account</strong>, the specific time of the money being refunded to your original payment account according to your payment mode/bank account (normally takes <strong>2-10 working days </strong>after Compliance Inbox initiates the refund).</p>
            <p style="margin-left: 4%;"><strong>Please Note: </strong>Refunds will be initiated for service price only, <strong>other fees like shipping guarantee, shipping, COD service charges are non-refundable. However, the refund amount depends upon the extent of loss or damage caused while the delivery of services.&nbsp; </strong></p>
            <p><strong>&nbsp;</strong></p>
            <h4><strong>1.&nbsp;&nbsp;&nbsp;&nbsp; </strong><strong>CANCELLATION</strong></h4>
            <p>If you have to cancel an order, please do so within 24 hours of placing the order for services. It is NOT possible to cancel part of your order. We recommend you cancel the entire order and re-purchase required services to simplify the transaction.</p>
            <ol>
            <li>If you cancel your order before your services have been initiated, we will refund the entire amount subject to deduction of charges if any and whereas you cancel your order after the initiation of service, there would be no refund.</li>
            <li>All cancellations are subject to a cancellation charge/bank transaction charge of 2.5% of your total order value.</li>
            <li>There are circumstances when the company might have to cancel a particular order.</li>
            <li>Once the subscription amount has been paid and the services have been availed in part or in full, cancellation cannot be done.</li>
            <li>Your receipt of an electronic or another form of order confirmation does not signify our final acceptance of your order and Compliance Inbox reserves the right at any time after the receipt of your order to accept or decline your order for any reason.</li>
            <li>Although our list of services is programmed to update automatically, Compliance Inbox reserves the right at any time after receipt of your order, to inform you via telephone or email or mobile text about a shortfall in the performance of the services. In all such cases, we have the right to accept or cancel your order with prior notice to you.</li>
            </ol>
            <p>Thank you for visiting Compliance Inbox!</p>
            <p>&nbsp;</p>
        </div>
    </div>
</div>
<!-- Privacy Policy Area End -->