import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { ViewportScroller } from "@angular/common";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    blogList:any;
    result:any;
    active: boolean = false;
    id:any;

    constructor(
        private router:Router,
        private _activatedRoute:ActivatedRoute,
        private formBuilder: FormBuilder,
        private _blogService: BlogService,
        private scroller: ViewportScroller
    ) { }

    ngOnInit(): void {
        this.id = this._activatedRoute.snapshot.params.id;
        console.log(this.router.url);
        /*console.log(this.id);
        if (this.router.url == "/blogs/") {
        this.active= true;
        }
        else {
        this.active= false;
        }*/
    }

    goDown1() {
        this.scroller.scrollToAnchor("enq-form");
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    getBlogList(id:any, title:any) {
        let slug = '';
        this.router.navigate(['/blogs/',  slug], { relativeTo: this._activatedRoute })
    }

}