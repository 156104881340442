import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ganesh-padakannya',
  templateUrl: './ganesh-padakannya.component.html',
  styleUrls: ['./ganesh-padakannya.component.scss']
})
export class GaneshPadakannyaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
