<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Cookies Policy</h3>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Privacy Policy Area -->
<div class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <p><strong><em>Last Updated on 01/03/2024</em></strong></p>
            <p>&nbsp;</p>
            <p>This Cookie Policy explains how COMPLIANCE iNBOX (collectively, “we” or the
                Company” or &quot;CiB&quot;) uses cookies and similar technologies to recognize and when you
                visit our website at https://complianceinbox.com/, It explain what these technologies
                are and why we use them, as well as your rights to control our use of them.
                In some cases, we may use cookies to collect personal information, or that becomes
                personal information if we combine it with other information.
            </p>
            <p>&nbsp;</p>
            <p><strong>&nbsp;</strong></p>
            <p><strong>What are cookies?</strong></p>

            <p>Cookies are small data files that are placed on your computer or mobile device when
                you visit a website. Cookies are widely used by website owners in order to make their
                website work, or to work more efficiently, as well as to provide reporting information.Cookies are small
                data files that are placed on your computer or mobile device when
                you visit a website. Cookies are widely used by website owners in order to make their
                website work, or to work more efficiently, as well as to provide reporting information.</p>
            <p>&nbsp;</p>
            <p>Cookies set by the website owner( in this case, Boston Financial Advisory Group) are
                called “First-Party cookies.” Cookies set by parties other than the website owner are
                called “Third-Party Cookies”.</p>
            <p>&nbsp;</p>
            <p><strong>USER INFORMATION</strong></p>
            <p>Third-party cookies enable third-party feature or functionality to be provided on or
                through the website. The parties that set these third-party cookies can recognize your
                computer both when it visits the website in questions and also when it visits certain
                other websites.</p>
            <p><strong>Why do we use cookies?</strong></p>


            <p>&nbsp;</p>
            <p>We use first and third-party cookies for several reasons. Some cookies are required for
                technical reasons in order for our website io operate, and we refer to these as
                “essential” or “Strictly necessary” Cookies. Other cookies also enable us to track and
                target the interests of our users to enhance the experience on our online properties.
                Third parties serve cookies through website for advertising, analytics and other
                purposes. This is described in more detail below.</p>
            <p><strong>Why can I control cookies?</strong></p>
            <p>
                You have the right to decide whether to accept or reject cookies. You can exercise your
                cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie
                Consent Manager allows you to select which categories of cookies you accept or reject.

                Essential cookies cannot be rejected as they are strictly necessary to provide you with
                services.
                The Cookie Consent Manager can be found in the notification banner and on our
                website. If you choose to reject cookies, you may still use our website though your
                access to some functionality and areas of our website may be restricted. You may also
                set or amend your web browser controls to accept or refuse cookies.
                The specific types of first- and third-party cookies served through our Website and the
                purposes they perform are described in the table below (please note that the specific
                cookies served may vary depending on the specific Online Properties you visit):
            </p>
            <p><strong>Unclassified Cookies:</strong></p>
            <p>These are cookies that have not yet been categorized. We are in process of classifying
                these cookies with the help of their providers.

            </p>
            <ol>
                <li>Name cinboxv2_session</li>
                <li> Provider ciblogin.com ,</li>
                <li>Type Server_Cookie</li>
            </ol>

            <p><strong>How can I control cookies on my browser?</strong></p>
            <p>As the means by which you can refuse cookies through your web browser controls vary
                from browser to browser, you should visit your browsers help menu for more
                information. The following is information about how to manage cookies on the most
                popular browsers:</p>
            <ul>
                <li>Chrome</li>
                <li>Internet Explorer</li>
                <li>Firefox</li>
                <li>Safari</li>
                <li>Edge</li>
                <li>Opera</li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>What about other tracking technologies, like web beacons?</strong></p>
            <p>Third parties may serve cookies on your computer or mobile device to serve advertising
                through our website. These companies may use information about your visits to this and
                other websites in order to provide relevant advertisements about goods and services
                that you may be interested in. They may also employ technology that is used to
                measure the effectiveness of advertisements. They can accomplish this by using cookies
                or web beacons to collect information about your visits to this and other sites in order
                to provide relevant advertisements about goods and services of potential interest to
                you. The information collected through this process does not enable us or them to
                identify your name, contact details, or other details that directly identify you unless you
                choose to provide these.</p>

            <p><strong>How often will you update this cookie policy? </strong></p>
            <p>We may update this Cookie Policy from time to time in order to reflect changes to the
                cookies we use for other operational, legal or regulatory reasons. Please therefore
                revisit this cookies policy regularly to stay informed about our use of cookies and related
                technologies.
                The date at the top of this cookie policy indicates when it was last updated.</p>
            <p><strong>How often will you update this cookie policy?</strong></p>
            <p>We may update this Cookie Policy from time to time in order to reflect changes to the
                cookies we use for other operational, legal or regulatory reasons. Please therefore
                revisit this cookies policy regularly to stay informed about our use of cookies and related
                technologies.
                The date at the top of this cookie policy indicates when it was last updated.</p>
            <p><strong>Where Can I Get Further Information?</strong></p>
            <p>If there are any questions regarding this Cookie Policy or other technologies, please
                email us at privacy@complianceinbox.com or write to BAFintech: Office No. 203/204,
                2nd Floor, Crystal Empire, Near Karnataka Bank, Behind D-Mart, Baner, Pune 411045.</p>
        </div>

    </div>
</div>
<!-- Privacy Policy Area End -->