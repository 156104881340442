<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Thank You</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Thank You</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<div class="about-area pt-40 pb-40">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-2"></div>
            <div class="col-lg-8 col-md-12 thank_div">
                <h2 class="about-title">Thank you for visiting our website.</h2>
                <p class="text-center">We promise to provide the desired results.</p>
            </div> 
        </div>
    </div>
</div>               