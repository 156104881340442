<!-- login-dialog.component.html -->

<h2>Login</h2>

<div>
  <p>
    <!-- Your login form or content here -->
    <!-- Example: -->
    <input type="text" placeholder="Username">
    <input type="password" placeholder="Password">
  </p>
  
  <p>
    <mat-checkbox formControlName="agreeTerms">I agree to all terms and conditions</mat-checkbox>
  </p>
  
  <p>
    <button mat-button color="primary" (click)="goToLogin()">Go to Login</button>
    <button mat-button color="accent" (click)="goToHome()">Home</button>
  </p>
</div>
