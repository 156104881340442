<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>PRADEEP KUMAR V</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>PRADEEP KUMAR V</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Pradeep-KV.jpg" alt="Images">
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>PRADEEP KUMAR V</h3>
                <b>DIRECTOR & CO-FOUNDER</b>
                <p  class="member_desc">Pradeep is an entrepreneur with over 17 years of broad-based experience in business management. As the President & CEO of Avanze Group, Pradeep sets the strategic direction of the company, manages overall business functioning, and oversees information technology, compliance, and the company’s financial systems.</p>
                <p>Prior to founding Avanze, Pradeep has worked with leading corporations in various roles and comes with diverse industry and functional expertise. He brings in extensive technical, management, and board-level experience to the company.</p>
                <p>Pradeep exhibits tenacious commitment to nurture concrete relationships with Clients, implement industry best practices, analyze key business drivers, and develop strategies to grow the business.</p>
                <p>Pradeep loves travelling, sports and is also a passionate yoga enthusiast.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

