<!-- Footer Area -->
<footer class="footer-area pt-40 pb-40">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images"></a>
                    </div>
                    <p>At Compliance Inbox, we continuously strive to provide our customers with the services, tools, support, and technology, required to take advantage of the Compliance market and the opportunities it offers. We are constantly developing new ways to make our client’s experience in managing compliance even better.</p>
                    <ul class="social-link">
                        <li class="footer-linkedin"><a href="https://www.linkedin.com/company/compliance-inbox" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li class="footer-facebook"><a href="https://www.facebook.com/complianceinbox" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li class="footer-twitter"><a href="https://twitter.com/CiB_BAFINTECH" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <!--<li class="footer-instagram"><a href="https://www.instagram.com/complianceinbox/" target="_blank"><i class='bx bxl-instagram'></i></a></li>--> 
                        <!--<li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>--> 
                        <li class="footer-youtube"><a href="https://www.youtube.com/channel/UCLdQZ0AG_GuQ6uiQiRy926A" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-us">About Product</a></li>
                        <li><a routerLink="/clients">Clients</a></li>
                        <li><a routerLink="/case-studies">Case Studies</a></li>
                        <li><a routerLink="/trust-center">Trust Center</a></li>
                        <li><a routerLink="/dsar">Do Not Sell My Info</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>&nbsp;</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms of Use</a></li>
                        <li><a routerLink="/refund-cancellation-policy">Refund and Cancellation Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-time'></i>
                            Mon - Fri: 9.00am - 9.00pm
                        </li>
                        <!--<li>
                            <i class='bx bx-location-plus'></i>
                            Office No. 203/204, 2nd Floor,
                            Crystal Empire, Near Karnataka Bank,
                            Behind D-Mart, Baner, Pune 411045.
                        </li>-->
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+918600477799">+91 86004 77799</a>  <span><a href="tel:+917488416447">+91 74884 16447</a></span> <span><a href="tel:+16095478495" style="margin-left: 5px;">+1 609 547 8495</a></span> <span><a href="tel:+971568554250">+971 56 855 4250</a></span>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:Info@complianceinbox.com">Info@complianceinbox.com</a>
                            <a href="mailto:sales@complianceinbox.com">sales@complianceinbox.com</a>
                        </li>
                    </ul>
                </div>
            </div>

            
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Copyright &copy; 2022. All Rights Reserved. Designed by <a href="https://bostonfagroup.com/" target="_blank">Boston Financial Advisory Group</a>.</p>

        </div>
    </div>
</div>
<!-- Copy-Right Area End -->