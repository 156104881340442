import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  blogList:any;
  result:any;
  p: number = 1;
  pagination: number = 1;
  categoryList:any;
  id:any;
  active: boolean = true;


  constructor(
    private router:Router,
    private _activatedRoute:ActivatedRoute,
    private formBuilder: FormBuilder,
    private _blogService: BlogService,
    private titleService: Title, 
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.id = this._activatedRoute.snapshot.params.name;
    console.log(this._activatedRoute.url);

    /*if (this.id == "") {
      this.id = 1;
    }*/

    this.getBlogList(this.id='');
    this.getBlogCategory();

    this.titleService.setTitle('Create and connect about compliance management tools | CiB');
    this.metaTagService.updateTag( { name:'keywords', content:'compliance management system, audit management system, compliance management software, compliance tools, compliance software, ca practice management software, regulatory compliance management tools, regulatory compliance management software, compliance software solutions, Compliance and Tax management'},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'Explore our blog for insightful articles, personal reflections, impactful resources, and ideas that talks about compliance management tools at Compliance inbox.'},"name='description'");
    
  }

  getBlogCategory() {
    const formData = new FormData();
    formData.append("api_token", "Comp@ibx!#");

    this._blogService.categoryData(formData).subscribe(res => {
      this.result = res;
      this.categoryList = this.result.data.category_list;
      console.log(this.categoryList);
    })
  }

  getBlogList(id:any) {
    const formData = new FormData();
    formData.append("api_token", "Comp@ibx!#");
    formData.append("title", id);
    
    this._blogService.blogData(formData).subscribe(res => {
      this.result = res;
      this.blogList = this.result.data;
      console.log(this.blogList);
    })
  }

  trackByFn(index:any, item:any) {
    //window.scrollTo(0, 0);
    return index; // or item.id
  }

  blogDetails(id:any, title:any) {
    debugger;
    let slug = title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      //.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');

     // console.log(slug);return false;
    this.router.navigate(['/blog-details',  slug], { relativeTo: this._activatedRoute })
  }


  selPag(event){
    this.p = event; 
    window.scrollTo(0, 0);
  }

  // 
}
