import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  homecontactData(data:any) {
    return this.http.post(`${environment.baseUrl}save_contact_details`, data);
  }

}
