<div class="container">
    <div class="row">
        <table class="table">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Website </th>
                <th scope="col">User name</th>
                <th scope="col">Email address</th>
                <th scope="col">Request as</th>
                <th scope="col">CCPA/Other</th>
                <th scope="col">Comment</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
                <ng-container >
                    <tr *ngFor="let row of dsars; let i = index;">
                        <th scope="row">{{i+1}}</th>
                        <td>{{rows.websiteUrl}}</td>
                        <td>{{rows.userName}}</td>
                        <td>{{rows.emailAddress}}</td>
                        <td>{{rows.requestAs}}</td>
                        <td>{{rows.ccpaOthers}}</td>
                        <td>{{rows.comment}}</td>
                        <td>{{rows.dateTime | date:('dd-MM-YYYY')}}</td>
 
                      </tr>
                </ng-container>
             
            </tbody>
          </table>
    </div>
</div>