<!-- Inner Banner -->
<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-40 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="contact-outer-div1" style="margin-top: 2%;">
                    <!--<span>Our</span>-->
                    <h2>India Office</h2>
                    <div class="contact-inner-div contact-inner-div1" style="">
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p># 54, 3rd Floor, 13th Main 17th Cross,
                                    Sector 6, Behind BDA Shopping Complex,
                                    HSR Layout, Bengaluru 560 102.</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone  ml5'></i>
                            <div class="content ml5">
                                <p><a href="tel:+918025724800">+91 80 2572 4800</a></p>
                                <span><a href="tel:+918025724600">+91 80 2572 4600</a></span>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p>403, 3rd Block, CMR Road, HRBR Layout, Bengaluru 560 043</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone  ml5'></i>
                            <div class="content  ml5">
                                <p><a href="tel:08046700000">+91 80 4670 0000</a></p>
                                <span>&nbsp;</span>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p>Office No. 203/204, 2nd Floor,
                                    Crystal Empire, Near Karnataka Bank,
                                    Behind D-Mart, Baner, Pune 411045.</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone  ml5'></i>
                            <div class="content  ml5">
                                <p><a href="tel:+918600477799">+91 86 0047 7799</a></p>
                                <span><a href="tel:+917488416447">+91 74 8841 6447</a></span>
                            </div>
                        </div>
                        
                        
                    </div> 
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="contact-outer-div1" style="margin-top: 3%;">
                    <!--<span>Our</span>-->
                    <h2>USA Office</h2>
                    <div class="contact-inner-div contact-inner-div2">
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p>The Schrafts Center Power House, Office No 341, 529 Main Street, Charlestown, MA – 02129</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone  ml5'></i>
                            <div class="content ml5">
                                <p><a href="tel:+16178865181">+1 617 886 5181</a></p>
                                <span><a href="tel:+18572144901">+1 857 214 4901</a></span>
                            </div>
                        </div>
                        
                        <div class="clearfix"></div>
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p>1000 N.West Street, Suite 1200, Wilmington, DE 19801</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone  ml5'></i>
                            <div class="content ml5">
                                <p><a href="tel:+3023194848">+1 609 937 7291</a></p>
                                <span><a href="tel:+18572144901">&nbsp;</a></span>
                            </div>
                        </div>
                        
                        
                    </div> 
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="contact-outer-div1" style="margin-top: 3%;">
                   <!--<span>Our</span>-->
                    <h2>UAE Office</h2>
                    <div class="contact-inner-div  contact-inner-div3">
                        <div class="contact-card col-md-7" style="float: left;">
                            <i class='bx bx-map'></i>
                            <div class="content">
                                <p>Unit 2540, 25th Floor, Iris Bay, Business Bay, Dubai, UAE</p>
                            </div>
                        </div>
    
                        <div class="contact-card col-md-5"  style="float: left;">
                            <i class='bx bxs-phone ml5'></i>
                            <div class="content  ml5">
                                <p><a href="tel:+97148714340">+971 56 855 4250</a></p>
                            </div>
                        </div>
    
                        <!--<div class="contact-card">
                            <i class='bx bx-envelope'></i>
                            <div class="content">
                                <h3>Email</h3>
                                <p><a href="mailto:info@gloz.com">info@gloz.com</a></p>
                                <span><a href="hello@gloz.com">hello@gloz.com</a></span>
                            </div>
                        </div>-->
                    </div> 
                </div>
            </div>
            
        </div>

        <div class="row contact_bottom_outer">
            <div class="col-md-12">
                <h2 style="text-align: center;margin-bottom: 7%;">Designed, Engineered & Promoted By</h2>
            </div>
                
            <div class="col-md-6 contact_boston">
                <a href="https://bostonfagroup.com/" target="_blank">
                    <img src="assets/img/contact/bfag-logo.png"  alt="Images">
                </a> 
            </div>
            <div class="col-md-6">
                <a href="https://www.avanzegroup.com/" target="_blank">
                    <img src="assets/img/contact/avanze-logo.jpg" alt="Images">
                </a>
            </div>
        </div>       
    </div>
</div>
<!-- Contact Section End -->



<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <p>For More Info</p>
                <h2>Get in Touch</h2>
                
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-70">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>For More Info & Free Demo</h2>
                            <p>Manage compliance and much more with the CiB. Schedule a free demo NOW!</p>
                        </div>

                        <form [formGroup]="contact" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" [ngClass]="{'is-invalid': submitted && f.name.errors }" class="form-control" placeholder="Name*">
                                        <div *ngIf="submitted && f.name.errors" class="text-danger small err_msg">
                                            <div *ngIf="f.name.errors?.required">Name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" formControlName="email_id" [ngClass]="{'is-invalid': submitted && f.email_id.errors }" class="form-control" required placeholder="Email*">
                                        <div *ngIf="submitted && f.email_id.errors" class="text-danger small err_msg"
                                            [ngClass]="{'is-invalid': submitted && f.email_id.errors }">
                                            <div *ngIf="f.email_id.errors?.required">Email is required</div>
                                            <div *ngIf="f.email_id.errors?.email">Please enter vaild email Id.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" pattern="\d*" maxlength="15" formControlName="mobile" class="form-control" placeholder="Mobile Number*">
                                        <div *ngIf="submitted && f.mobile.errors" class="text-danger small err_msg"
                                            [ngClass]="{'is-invalid': submitted && f.mobile.errors }">
                                            <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                            <div *ngIf="f.mobile.errors?.pattern">Invalid mobile number</div>
                                        </div>    
                                    </div>
                                </div>

                                

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="organization" [ngClass]="{'is-invalid': submitted && f.organization.errors }" class="form-control" placeholder="Organization Name*">
                                        <div *ngIf="submitted && f.organization.errors" class="text-danger small err_msg">
                                            <div *ngIf="f.organization.errors?.required">Organization is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="message" cols="30" rows="4"  placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 ">
                                    <mat-form-field style="background-color: #f8f9fb;border: 1px solid #ebebeb;border-radius: 0.25rem;height: 61px;
                                    line-height: 2;
                                    padding-left: 15px;">
                                        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="schedule_dt" placeholder="Choose Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker ></mat-datepicker>
                                        <mat-error *ngIf="f.schedule_dt.hasError('required')"> Schedule Date is required</mat-error>
                                      </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Choose Time*</mat-label>
                                    
                                        <!-- The timepicker input -->
                                        <input matTimepicker #t="matTimepicker"  [strict]="false" id="timepicker-example" mode="24h"
                                        formControlName="schedule_time" >
                                        <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
                                        <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon>
                                        <mat-error *ngIf="f.schedule_time.hasError('required')"> Schedule Time is required</mat-error>
                                      </mat-form-field>
                                </div>
                                <mat-checkbox formControlName="agreeTerms">
                                    I agree to all <a href="/terms-conditions" target="_blank">terms and conditions</a>
                                </mat-checkbox>
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Schedule Demo</button>
                                </div>
                            </div>
                        </form>
                        <div class="alert alert-success mt-4 successmsg" role="alert" *ngIf="resultTrue">
                            Thank You for showing interest. Our team would get back to you shortly.
                        </div>
                        <div class="alert alert-danger successmsg" role="alert" *ngIf="resultFalse">
                            Something went wrong please try again
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->


<!-- Map Area -->
<div class="map-area">
    <!--<iframe src="https://www.google.com/maps/d/u/0/embed?mid=153BHP_6u2nZgpSfs6hz-5J3V4jgoRBUd&ehbc=2E312F"></iframe>-->
    <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1npG1T_JGtfFSLUXvavYATodkeLYsuKM&ehbc=2E312F" ></iframe>
</div>
<!-- Map Area End -->



