import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
  constructor(private router: Router) {}

  goToLogin() {
    // Navigate to the login page
    this.router.navigateByUrl('https://ciblogin.com/Login');
  }

  goToHome() {
    // Define the logic to navigate to the home page here
  }
}
