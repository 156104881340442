import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { SameerPaddalwarComponent } from './components/pages/team/sameer-paddalwar/sameer-paddalwar.component';
import { GaneshPadakannyaComponent } from './components/pages/team/ganesh-padakannya/ganesh-padakannya.component';
import { PradeepKumarVComponent } from './components/pages/team/pradeep-kumar-v/pradeep-kumar-v.component';
import { VinodKainikatComponent } from './components/pages/team/vinod-kainikat/vinod-kainikat.component';
import { RamuRadhakrishnanComponent } from './components/pages/team/ramu-radhakrishnan/ramu-radhakrishnan.component';
import { AmarTumplwarComponent } from './components/pages/team/amar-tumplwar/amar-tumplwar.component';
import { UjjalMukherjeeComponent } from './components/pages/team/ujjal-mukherjee/ujjal-mukherjee.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { RefundCancellationPolicyComponent } from './components/pages/refund-cancellation-policy/refund-cancellation-policy.component';
import { AmitMulgundComponent } from './components/pages/team/amit-mulgund/amit-mulgund.component';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component';
import { TrustCenterComponent } from './components/pages/trust-center/trust-center.component';
import { DsarComponent } from './components/pages/dsar/dsar.component';
import { LoginDialogComponent } from './components/pages/login-dialog/login-dialog.component';
import { CookiesPolicyComponent } from './components/pages/cookies-policy/cookies-policy.component';
import { IsoPageComponent } from './components/pages/iso-page/iso-page.component';
import { CcpaPageComponent } from './components/pages/ccpa-page/ccpa-page.component';
import { DsarDataviewComponent } from './components/pages/dsar-view/dsar-view.component';
// import {TrustCenterComponent} from './components/pages/trust-center/trust-center.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'sameer-paddalwar', component: SameerPaddalwarComponent},
    {path: 'ganesh-padakannya', component: GaneshPadakannyaComponent},
    {path: 'pradeep-kumar-v', component: PradeepKumarVComponent},
    {path: 'vinod-kainikat', component: VinodKainikatComponent},
    {path: 'ramu-radhakrishnan', component: RamuRadhakrishnanComponent},
    {path: 'amar-tumplwar', component: AmarTumplwarComponent},
    {path: 'ujjal-mukherjee', component: UjjalMukherjeeComponent},
    {path: 'amit-mulgund', component: AmitMulgundComponent},
    {path: 'about-us', component: AboutComponent},
    {path: 'clients', component: ClientsComponent},
    {path: 'case-studies', component: CaseStudiesComponent},
    {path:'case-study/:name',component: CaseStudyComponent},
    {path: 'blogs/:name', component: BlogsComponent},
    {path: 'blog-details/:name', component: BlogDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'trust-center', component:TrustCenterComponent},
    // {path: 'trust-center', component: TrustCenterComponent},
    {path: 'refund-cancellation-policy', component: RefundCancellationPolicyComponent},
    {path: 'contact', component: ContactComponent},
    {path:'dsar', component: DsarComponent},
    {path:'dsar-view', component: DsarDataviewComponent},
    {path: 'thank-you', component: ThankYouComponent},
    {path:'login-dialog', component: LoginDialogComponent},
    {path:'cookies-policy', component: CookiesPolicyComponent},
    {path:'iso-page' , component: IsoPageComponent},
    {path:'ccpa-page' , component: CcpaPageComponent},
    {path: '**', component: ErrorComponent}, // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }