<style>
    .privacy-form {
    background-color: #f2f2f2; /* Adjust the color as needed */
    padding: 20px;
    border-radius: 10px;
}

</style>


<div class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <div class="privacy-form">
                <h3 class="text-center">CCPA Compliant</h3>
                <p class="">Under the California Consumer Privacy Act of 2018 (“CCPA”), California residents
                    have the right to opt-out of the “sale” of their personal information. We do not sell
                    your personal information. While we do not receive financial compensation for your
                    personal information, we may share it with third parties to personalize your
                    experience on our site, improve our site and services, and so that we can engage you
                    with interest-based advertising on other websites. To exercise this right, please see
                    the options detailed below. Please visit our Website Privacy and Cookie Policy for
                    further details about our privacy practices.</p>
                <p class="text-center"></p>
                <p>&nbsp;</p>

                <h3 h3 class="text-center">Submit your request via email or fill out a request form</h3>
                <p><strong>To opt-out of the “sharing” of your personal information, please exercise your choice
                        below:</strong></p>
                <p>&nbsp;</p>
                <ol>
                    <li>Email request. You may submit your request to opt out of the sale of your
                        personal information described above by contacting us at
                        <a href="mailto:privacy@complianceinbox.com">privacy@complianceinbox.com</a>.</li>

                    <p>&nbsp;</p>
                    <li>Request form. You may submit a request via DSAR <a routerLink="/dsar">(Data Subject Access
                            Request)</a></li>
                </ol>
                <p><strong>Note:</strong> Please note that we may need to request certain information from you to
                    verify your identity or otherwise process your request. If you are making this request
                    on behalf of someone else, as their authorized agent, we may request you to provide
                    us with a copy of a valid power of attorney or evidence of their written
                    authorization. We may deny requests from agents who cannot demonstrate their
                    authorization.</p>
            </div>
        </div>
    </div>
</div>
