<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>SAMEER PADDALWAR</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>SAMEER PADDALWAR</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Sameer-P.jpg" alt="Images">
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>SAMEER PADDALWAR</h3>
                <b>DIRECTOR & CO-FOUNDER</b>
                <p  class="member_desc">Sameer is the promoter and guiding force behind the Boston Financial Advisory group. A passionate individual with three decades plus experience, the drive to excel coupled with strategic vision has led the group towards spiralling revenue growth and performance improvements for over a decade.</p>
                <p>His impeccable academic record with rich expertise of over 20 years with premier IT and Manufacturing Organizations has always been something the firm depends on. He has also successfully spearheaded financial operations for India, APAC & EMEA operations for various multinationals. He has worked with some big players like Religare Group, Bell Labs, Proxim Corporation, Suessen Asia Ltd, Finolex Group etc.</p>
                <p>Sameer’s expertise is in Global Corporate Structuring, strategic partners and investor relationship management, M & A synergies, post-acquisition business integration management, financial re-engineering, systems transformation and reducing product portfolio complexity.  </p>
                <p>He is a Certified Public Accountant, CMA, CFM.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

