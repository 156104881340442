<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>AMIT MULGUND</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>AMIT MULGUND</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Amit-M.jpg" alt="Images">
                
                
            </div>
            <div class="col-md-8 member_desc_div">
                <h3 class="member_name">AMIT MULGUND</h3>
                <b>MANAGING DIRECTOR – EMEA</b>
                <p class="member_desc">Amit Mulgund is a Chartered Accountant from the Institute of Chartered Accountants, India. He has held senior management positions in large conglomerates, MNC and SME sector spread over diverse geographies like India, UAE, and few African countries.</p>
                <p>An Ex-Group CFO and astute finance professional, he has experience of more than 20 years in the core F&A areas of creating tax-efficient cross border Holding-Subsidiary structures, Treasury and Risk management, Working capital management, Credit insurance management, ERP implementation, Valuation of companies and Mergers & Acquisitions.</p>
                <p>He is the Managing Director of Boston Financial Advisory Group EMEA Region. He embraces the diverse environment and promotes creativity. His effective problem-solving skills and breakthrough results make him an asset to the corporate world.</p>
                <p>Amit has also successfully completed certificate courses in prestigious B Schools such as INSEAD (France) and Indian Institute of Management (Ahmedabad).</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

