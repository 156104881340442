import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  submitted = false;
  resultFalse = false;
  resultTrue = false;
  result:any;
  contact: any;

  constructor(
    private router:Router,
    private _activatedRoute:ActivatedRoute,
    private formBuilder: FormBuilder,
    private _contactService: ContactService,
    public datepipe: DatePipe,
    private titleService: Title, 
    private metaTagService: Meta
  ) { }

  defaultValue= {hour: 13, minute: 30};
  

  timeChangeHandler(event: Event) {
    console.log(event);
  }

  invalidInputHandler() {
    // some error handling  
  }

  ngOnInit(): void {
    this.contactForm();

    this.titleService.setTitle('About us | Practice Management Software for CA');
    this.metaTagService.updateTag( { name:'keywords', content:'practice management software for ca'},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'CiB is a specially designed Practice management software for CA, Company Secretaries, Lawyers and the members. CiB is a compliance and risk management agency'},"name='description'");
    
  }

  get f() { return this.contact.controls; }

  contactForm() {
    
    this.contact = this.formBuilder.group({
      name: ['', Validators.required],
      email_id: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      organization: ['', Validators.required],
      message: [''],
      schedule_dt: ['', Validators.required],
      schedule_time: ['', Validators.required],
    })
  }

  onSubmit() {
    //this._router.navigate(['dashboard']);
    
    this.submitted = true;
    if (this.contact.invalid) {
      return;
    }
    else {
      const formData = new FormData();
      

      let name = this.contact.value.name;
      let email_id = this.contact.value.email_id;
      let mobile = this.contact.value.mobile;
      let organization = this.contact.value.organization;
      let message = this.contact.value.message;

      let scheduledt = this.contact.value.schedule_dt;
      let dateFormat1 = this.datepipe.transform(scheduledt, 'dd-MM-yyyy');

      let scheduletime = this.contact.value.schedule_time;
      let schedule_time = this.datepipe.transform(scheduletime, 'hh:mm');

      formData.append('name', name);
      formData.append('emailid', email_id);
      formData.append('mobile', mobile);
      formData.append('organization', organization);
      formData.append('message', message);
      formData.append('schedule_dt', dateFormat1);
      formData.append('schedule_time', schedule_time);
      
      

     // console.log(formData);return false;

      this._contactService.homecontactData(formData).subscribe(res => {
        //this.router.navigate(['thank-you']);
        let response:any = res;
           
        if(response['result'] == 'F') {
          this.resultFalse = true;
          this.resultTrue = false;
       
        } else {  
          this.resultFalse = false;
          this.resultTrue = true;
          this.contact.reset();
          this.submitted = false;
          
        }
       
      })
    }

  }
  
}
