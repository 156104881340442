import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vinod-kainikat',
  templateUrl: './vinod-kainikat.component.html',
  styleUrls: ['./vinod-kainikat.component.scss']
})
export class VinodKainikatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
