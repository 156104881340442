import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CasestudyService {

  constructor(private http: HttpClient) { }

  casestudyData(data:any) {
    return this.http.post(`${environment.baseUrl}fetch_case_study`, data);
  }

  
  casestudyDetails(data:any) {
    return this.http.post(`${environment.baseUrl}case_study_detail`, data);
  }

}
