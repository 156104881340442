<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>COMPLIANCE iNBOX’s Privacy Policy</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Privacy Policy Area -->
<div class="privacy-policy-area pt-100 pb-70">
    <div class="container">
        <div class="single-content">
            <p><strong><em>Last updated: 01/03/2024 </em></strong></p>
            <p><strong>General Information</strong></p>
            <p>We, at COMPLIANCE iNBOX and our associate companies (collectively, “we” or the
                Company” or &quot;CiB&quot;), respect the privacy of anyone who chooses to provide us with their
                individually identifying or identifiable information (“Personal Information”), and we are
                committed to protect their privacy.</p>
            <p>&nbsp;</p>
            <p>This Privacy Policy (&quot;Privacy Policy&quot; or &quot;Policy&quot;) describes, among other things, the
                types
                of information we may collect, how that information may be used, and with whom the
                information may be shared. This Privacy Policy applies to our Website and Portal
                whenever you use or interact with our website https://www.complianceinbox.com (the
                &quot;Website&quot;), and to information collected and processed through our SaaS based portal
                https://ciblogin.com/Login (the &quot;Software&quot;), or the services we provide including any
                content or material provided thereon (collectively with the Software, the &quot;Services&quot;), as
                well as contact information we collect from vendors and partners for the purpose of
                management and execution of the respective engagements.</p>
            <p>If you do not agree to this Policy, please avoid using our Website, Software or Services.
                You are not legally required to provide us with any Personal Data, but without it we will
                not be able to provide you with the best experience.</p>
            <p>We encourage you to read this Privacy Policy carefully and use it to make informed
                decisions.</p>
            <p>&nbsp;</p>
            <p><strong>What Information Do We Process and How We Collect It?</strong></p>
            <p> <strong> 1) Contact Information</strong>- When using the Website
                (https://complianceinbox.com/contact) or when we receive the business contact
                information from ciblogin portal (https://ciblogin.com/Login). We may collect
                Personal Information, such as your name, address, telephone number and email
                address (&quot;Contact Information&quot;). We do not require that visitors to our Website
                provide us with Contact Information and you may visit our Website anonymously
                (by using the ‘Incognito’ status on your browser). In this case, we do not collect
                Contact Information when you visit our website, unless you choose to provide it
                to us (however, we do collect certain Online Identifiers, as described below). The
                decision to provide Contact Information is voluntary and you may withdraw your
                consent at any time by contacting us in one of the ways described in the &#39;How to

                contact us&#39; section below. However, if you do not provide the Contact
                Information requested, you may not be able to proceed with the activity or enjoy
                the full experience of our Website and portal.</p>
            <p>&nbsp;</p>
            <p> <strong> 2)</strong> We collect Contact Information of the relevant personnel of our clients, business
                partners, service providers and vendors, in the course of our respective
                engagements and for the purpose of management and execution of such
                engagements.</p>
            <!-- <p>We collect information from the user when creating a user account or when the user is browsing for products, transacting an online purchase of products through the Website or while posting comments, uploading content or participating in any offers or contests or availing special gifts or communicating with customer support by phone, email or otherwise.</p>
            <p>&nbsp;</p>
            <p>We receive, scrutinize and store User Information for security and technical reasons such as IP address, login id, email address, password, device connection information, transaction history, date, time and duration of the session on the Website.</p>
            <p>We automatically receive the URL of the site from which a user came and the site to which the user goes when the user leaves the Website. The Website receives the IP address of the user&rsquo;s computer and the type of web browser a user is using, email patterns, etc. We will occasionally ask the user to complete optional online surveys. These surveys may ask the user for contact information and demographic information (eg. zip code, age or income level).</p>
            <p>&nbsp;</p>
            <p>We use data collection devices such as &ldquo;cookies&rdquo; on certain pages of the website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. &ldquo;Cookies&rdquo; are small files placed on your hard drive that assist us in providing our services. We also use cookies to allow the users to enter their passwords less frequently during a session. Cookies can also help us provide information that is targeted to the user&rsquo;s interests. Most cookies are &ldquo;session cookies,&rdquo; meaning that they are automatically deleted from the user&rsquo;s hard drive at the end of a session. The user is always free to decline our cookies if the browser permits, although in that case the user may not be able to use certain features on the website and may be required to re-enter the password more frequently during a session. Additionally, the user may encounter &ldquo;cookies&rdquo; or other similar devices on certain pages of the website that are placed by third parties. The Company does not control the use of cookies by third parties.</p>
            <p>&nbsp;</p>
            <p>The user can add or update the User Information on a regular basis. When the user updates the User Information, We will keep a copy of the prior version for its records.</p>
            <p>&nbsp;</p>
            <p><strong>SERVICES OVERVIEW</strong></p>
            <p>As part of the registration process on the Site, We may collect the following personally identifiable information about you:</p>
            <ul>
            <li>Name including first and last name,</li>
            <li>alternate email address,</li>
            <li>mobile phone number and contact details,</li>
            <li>Postal code,</li>
            <li>Demographic profile (like your age, gender, occupation, education, address, etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information.</li>
            </ul> -->
            <p>&nbsp;</p>
            <p><strong>How Do We Use the Information We Collect?</strong></p>
            <p>The information we collect, which may include Personal Information, is used for
                legitimate business purposes, only to the extent required or otherwise reasonably
                necessary for one or more of our functions or activities, and while maintaining the
                right to privacy. Such legitimate business purposes include:</p>
            <ol>
                <li>Conducting our business (such as, reaching out to prospective clients).</li>
                <li>Setting up our client’s account at ciblogin.com and providing our Services to
                    them.</li>
                <li>iSetting up our client’s account and providing our Services to them via ciblogin.</li>
                <li>Performing research, technical diagnostics and analytics with regard to the
                    Website and portal.</li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>What Are the Legal Bases for Processing Of Personal Information?</strong></p>
            <p>We will process Personal Information based on either of the following legal bases,
                each of which is prescribed by relevant data protection laws.</p>
            <ol>
                <li>
                    Performance of a contract, compliance with a legal obligation: We process
                    Personal Information where it is necessary for the performance of our
                    contract with you, or in order for us to comply with our various legal and/or
                    regulatory responsibilities.
                </li>
                <li>
                    Legitimate interests: We also process Personal Information where we deem
                    such processing to be in our (or a third party’s) legitimate interests and
                    provided always that such processing will not prejudice your interests, rights
                    and freedoms. Examples of our processing in accordance with our legitimate
                    interests would include: (i) where we disclose Personal Information to any
                    one or more of our associate companies Like (www.bostonfagroup.com) (ii)
                    processing for the purposes of ensuring network and information security,
                    including preventing unauthorized access to our electronic communications network.
                     (iii) sharing personal information with our advisers and professional
                    services providers (such as auditors).
                </li>
            </ol>
            <p>&nbsp;</p>
            <p><strong>Consent</strong></p>
            <p>On certain occasions we may ask for your consent to processing Personal
                Information. In these instances, your Personal Information will be processed in
                accordance with such consent, and you will be able to withdraw this consent in
                writing at any time (for further information, see &quot;WHAT ARE YOUR RIGHTS&quot;
                section below).</p>
            <p>&nbsp;</p>
            <!-- <ol>
                <li>third party service providers engaged by us to perform the services on our behalf,</li>
                <li>third party service providers for the purpose of the Website&rsquo;s security, maintenance, repair
                    or other technical reasons.</li>
            </ol> -->
            <p>&nbsp;</p>
            <p><strong>Cookies And Tracking Technologies</strong></p>
            <p>We may use cookies and other tracking technologies to collect and store your
                information. Specific information about how we use such technologies and how
                you can refuse certain cookies is set out in our cookie policy
                bostanfagroup.com/cookie-policy</p>
            <p>&nbsp;</p>
            <p><strong>International Data Transfer</strong></p>
            <p>Since we operate globally, it may be necessary to transfer, store and process
                Personal Information in other countries in which we or our affiliates, subsidiaries
                or service providers (including the sub-processors) maintain facilities, such as the
                United States, Israel, the European Union and the United Kingdom. The data
                protection and other laws of these countries may be different than those in your
                jurisdiction of residence</p>
            <p>&nbsp;</p>
            <p><strong>How Long Do We Retain the Information We Collect?</strong></p>
            <p>Unless you instruct us otherwise for justified reasons, we retain the Personal
                Information we collect for as long as needed to manage our business and provide
                our services (including marketing communications, as described herein) and to
                comply with our legal obligations, resolve disputes and enforce our agreements
                (including exercising any of our rights under our agreements, such as audit and
                record-keeping).
                Our default retention policy is a 8 year as per Laws.</p>
            <p>&nbsp;</p>
            <p><strong>How Do We Safeguard Your Information?</strong></p>
            <p>We are committed to making reasonable efforts, in accordance with market best
                practices and legal requirements, to ensure the Security, Confidentially and
                Integrity of the Personal Information. We take great care in implementing and
                
                maintaining the security of our website, software and anywhere the Personal
                Information is stored. Access to the Personal Information is based on the ‘least to
                know’ concept together with role-based access control systems, ensuring only
                authorized access to the Personal Information. We employ market best practice
                security measures to ensure the safety of the Personal Information and prevent
                unauthorized use of any such information. Although we take steps to safeguard
                such information, we cannot be responsible for the acts of those who gain
                unauthorized access or abuse our software, and we make no warranty, express,
                implied or otherwise, that we will prevent such access. If a password is used to
                help protect your accounts and Personal Information, it is your responsibility to
                keep your password confidential.
                for further information about our privacy and security practices, please visit our
                Trust Center(https:/bostonfagroup.com/trust-center)
            </p>
            <p>&nbsp;</p>
            <p><strong>What are your rights?</strong></p>
            <p>We acknowledge that different people have different privacy concerns and
                preferences. Our goal is to be clear about what information we collect so that
                you can make meaningful choices about how it is used. We allow you to exercise
                certain choices, rights, and controls in connection with your Personal
                Information. Depending on your relationship with us, your jurisdiction and the
                applicable data protection laws that apply to you, you have the right to control
                and request certain limitations or rights to be executed.</p>
            <p>&nbsp;</p>
            <p><strong>California Residents</strong></p>
            <p>California Civil Code Section 1798.83, also known as the “Shine the Lights” law
                permits our users who are California residents to request and obtain from us,
                once a year and free of charge, information about categories of personal
                information if we disclosed to third parties for direct marketing purpose. If you
                are a California resident and would like to make such a request, please submit
                your request in writing to us using the contact information provided below.</p>
            <p>&nbsp;</p>
            <p><strong>CCPA Privacy Notice</strong></p>
            <p>This section applies only to California Residents, Under the California Consumer
                Privacy Act(CCPA), You have the rights listed below.<br/>
                The California code of regulations defines a “residents” as</p>
                <ol>
                    <li>Every residents who is in the State of California for other than a temporary or
                        transitory purpose.</li>
                        <li>Every individuals who is domiciled in the State of California who is outside the
                            State of California for a temporary or transitory purpose.</li>
                </ol>
                <p>All other individuals are defined as “Non-residents”</p>
                <p><strong>Rights Under CCPA</strong></p>
                <p><strong>The right to know and access what Personal Information the business has
                    collected-</strong></p>
            <p>The right to know and access what Personal Information we collected,
                including the categories of Personal Information, the sources from which the
                Personal Information is collected, the business or commercial purpose for
                collecting, selling, or sharing Personal Information, the categories of third parties
                to whom we disclose Personal Information, and the specific pieces of Personal
                Information we collected about you.</p>
            <p>&nbsp;</p>
            <p><strong>Deletion Rights-</strong></p>
            <p>The right to delete Personal Information that we collected from
                you, subject to certain exceptions.</p>
                <p><strong>Correct Inaccurate Data-</strong></p>
                <p>The right to correct inaccurate Personal Information
                    that we maintain about you.</p>
            <p>&nbsp;</p>
            <p><strong>Opt-Out of Sharing for Cross-Contextual Behavioral Advertising-</strong></p>
            <p>You have the
                right to opt-out of the “sharing” of your Personal Information for “cross-
                contextual behavioral advertising” (all as defined under the CCPA), often referred
                to as “interest-based advertising” or “targeted advertising”.</p>
            <p>&nbsp;</p>
            <p><strong>Opt-out from selling-</strong></p>
            <p>The right to opt-out of the &quot;sale&quot; or &quot;sharing&quot; (as defined
                under the CCPA) of Personal Information.</p>
            <p>&nbsp;</p>
            <p><strong>Limit the Use or Disclosure of Sensitive Personal Information (SPI)-</strong></p>
            <p>You have the
                right to request to limit the collection of your SPI to that use which is necessary
                to maintain our Service.</p>

            <p><strong>Non-Discrimination-</strong></p>
            <p>The right not to receive discriminatory treatment by us for
                exercising your privacy rights, including denying goods or services, charging
                different prices or rates for goods or services, providing a different level or
                quality of goods or services, etc.</p>
            <p>We may, however, charge different prices or rates, or provide a different level or
                quality of goods or services, if that difference is reasonably related to the value
                provided to us by your Personal Information.</p>
            <p>&nbsp;</p>
            <p><strong>Data Portability-</strong></p>
            <p>You may request to receive a copy of your Personal Information,
                including specific pieces of Personal Information, including, where applicable, to
                obtain a copy of the Personal Information you provided to us in a portable
                format.</p>
            <p>&nbsp;</p>
            <p><strong>Minors</strong></p>
            <p>Our website, products and services are all directed and designated to people who
                are above the age of majority (as determined under the applicable laws where
                the individual resides). If you have any reason to believe that a minor has shared
                any Personal Information with us, please contact us as set forth below.</p>
            <p>&nbsp;</p>
            <p><strong>Update or Amendments to the Privacy Policy</strong></p>
            <p>We may revise this Privacy Policy from time to time, in our sole discretion, and
                the most current version will always be posted on our website. We encourage
                you to review this Privacy Policy regularly for any changes.
                In the event of material changes to this Privacy Policy, that affect the use of our
                SaaS based software, we will inform of such change in the CibPortal for
                customers. The continued use of our SaaS software, following the notification of
                such amendments, constitutes acknowledgement and consent of such
                amendments to the Privacy Policy.</p>
            <p>&nbsp;</p>
            <p><strong>How to contact Us</strong></p>
            <p>If there are any questions regarding this Privacy Policy or the information that we
                collect about you, or if you feel that your privacy was not treated in accordance
                with this Privacy Policy, you may contact our Data Protection Officer at:
                <a href="mailto:privacy@complianceinbox.com">privacy@complianceinbox.com</a> or at BAFintech: Office No. 203/204,
                2nd Floor, Crystal Empire, Near Karnataka Bank, Behind D-Mart, Baner, Pune 411045.</p>
            
        </div>


    </div>
</div>
<!-- Privacy Policy Area End -->