<style>
   /* background-color: rgba(128, 128, 128, 0.081); */
    .custom-bg-color {
        /* Default background color */
        background-color: #1abc9c;
        /* Blue */
        transition: background-color 0.3s ease;
        /* Smooth transition */
    }

    .custom-text-color {
        /* Default text color */
        color: #030303;
        /* White */
        transition: color 0.3s ease;
        /* Smooth transition */
    }

    /* Hover styles */
    .custom-bg-color:hover {
        background-color: #6fe6ce;
        /* Green */
    }

    .custom-text-color:hover {
        color: #000000;
        /* Black */
    }

    .custom-text-color .card-text {
        font-weight: '400';
        /* Make the text bold */
        font-size: 16px;
        /* Adjust the font size as needed */
    }

    .custom-column {
        margin-right: 80px;
        /* Adjust the margin as needed */
        margin-left: 80px;
        /* Adjust the margin as needed */
    }

    .email-address {
        margin-left: 36px;
    }
    .custom-img {
    width: 80px; /* Adjust the width as per your requirement */
    height: 80px; /* Adjust the height as per your requirement */
    object-fit: cover; /* Ensures the image retains its aspect ratio */
}
.custom-bg-color {
    background-color: #fff; /* Add your desired background color */
    border-radius: 10px; /* Add border-radius for rounded corners */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); /* Add box-shadow for shadow effect */
}

.custom-bg-color:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2); /* Add hover effect for box-shadow */
}


</style>
<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Trust Center</h3>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul> -->
        </div>
    </div>
</div>
<!-- Inner Banner End -->
 
 
  
    
<div class="container my-5">
	<div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
		<div class="col-lg-7 p-3 p-lg-5 pt-lg-3">
			<div class="lc-block mb-3">
				<div editable="rich">
					<h5 >Strengthen your infrastructure resilience, security, compliance,&nbsp; admin control, and more with Bostan Financial Advisory Group<p></p>
						<p></p>
					</h5>
				</div>
			</div>

			<div class="lc-block mb-3">
				<div editable="rich">
					<p class="lead">Our goal is to create the most trusted platform, and your trust is
                        at the center of everything we do. Our
                        products, policies, processes and systems are designed to protect you, your rights and your
                        data;
                        creating a safe place for you to share your valuable information.
					</p>
				</div>
			</div>

			<!-- <div class="lc-block d-grid gap-2 d-md-flex justify-content-md-start"><a class="btn btn-primary px-4 me-md-2" href="#" role="button">Click me, I'm a button</a>
				<a class="btn btn-outline-secondary px-4" href="#" role="button">Click me, I'm a button</a>
			</div> -->
		</div>
		<div class="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
			<div class="lc-block"><img class="rounded-start w-100" src="assets/img/about/Boston.jpg" alt="Photo by Diego PH" width="720"></div>
		</div>
	</div>
</div>
 
 
  
  
<!-- Privacy Policy Area -->
<div class="privacy-policy-area pt-100 pb-70">
    <div class="container background-cr">
        <div class="single-content">
            <!-- <div class="row align-items-center mbp-4">
                <div class="col-lg-4 col-md-12">
                    <div class="">
                        <img src="assets/img/about/Boston.jpg" class="img-fluid  about_bfag_img" alt="Images">
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="about-content">
                        <p style="font-size: 20px;">Strengthen your infrastructure resilience, security, compliance,
                            admin control, and more with Bostan Financial Advisory Group</p>

                        <p style="font-size: 20px;">Our goal is to create the most trusted platform, and your trust is
                            at the center of everything we do. Our
                            products, policies, processes and systems are designed to protect you, your rights and your
                            data;
                            creating a safe place for you to share your valuable information.</p>
                    </div>
                </div>
            </div> -->
            <!-- Privacy Policy Area -->

            <div class="privacy-policy-area pt-20 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 mb-4">
                            <div class="card custom-bg-color custom-text-color">
                                <div class="card-body text-center">
                                    <img src="assets/img/NewIcon/file.png" alt="Image 1" class="custom-img">
                                    <h5 class="card-title">Privacy Policy</h5>
                                    <p class="card-text text-center">We protect the confidentiality of information and
                                        the privacy of its clients, candidates, and other users of the website</p>
                                    <p class="card-text text-center"><a href="/privacy-policy">Read More</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card custom-bg-color custom-text-color">
                                <div class="card-body text-center">
                                    <img src="assets/img/NewIcon/iso-symbol.png" alt="Image 2" class="custom-img">
                                    <h5 class="card-title">ISO 27001</h5>
                                    <p class="card-text text-center">We are ISO 27001 Compliant Company to ensure your
                                        data is stored securely and following all secure methods.</p>
                                    <br />
                                    <p class="card-text text-center"><a href="/iso-page">Read More</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card custom-bg-color custom-text-color">
                                <div class="card-body text-center">
                                    <img src="assets/img/NewIcon/law.png" alt="Image 3" class="custom-img">
                                    <h5 class="card-title">CCPA</h5>
                                    <p class="card-text text-center">We do not sell your personal information or share
                                        your information under the California Consumer Privacy Act of 2018 (“CCPA”).</p>
                                    <p class="card-text text-center"><a href="/ccpa-page">Read More</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- Privacy Policy Area End -->