import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  id:any;
  blogDetails:any;
  result:any;

  constructor(
    private _blogService: BlogService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private titleService: Title, 
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.id = this._activatedRoute.snapshot.params.name;

    let cantitle=this.id;
    var title = cantitle.replaceAll("-", " ");

    console.log(this.id);
    this.getBlogDetails(this.id);

    this.titleService.setTitle(title);
    this.metaTagService.updateTag( { name:'keywords', content:title},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'Explore our blog for insightful articles, personal reflections, impactful resources, and ideas that talks about compliance management tools at Compliance inbox.'},"name='description'");
    
  }

  getBlogDetails(id:any) {
    const formData = new FormData();
    formData.append('title', id);

    this._blogService.blogDetailData(formData).subscribe(res => {
      this.result = res;
      this.blogDetails = this.result.data;
      console.log(this.blogDetails);
    })
  }

}
