import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from './shared/canonical.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import APIURL from './helper/apiUrl';
import { GeoLocationService } from './services/geolocation/geolocation.service';

declare let $: any;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    isEssential: boolean = false;
    isadvertising: boolean = false;
    isAandC: boolean = false;
    isPandF: boolean = false;
    isSandN: boolean = false;
    geoLocation!:string;
    isunclassified: boolean = false;
    showBanner:any;
    coockiesFlag:boolean = false;
    // cookieData!:UserCoockiesDetails;
    cookieData = {
        ipAddress:'',
        cookies: '',
        longiTude: '',
        latiTude: '',
        accuracy: ''
    };
    @ViewChild('cookieDialog') cookieDialog!: TemplateRef<any>;

    constructor(private router: Router, 
        private metaTagService: Meta, private title: Title,
        private canonicalService: CanonicalService, 
        private cookieService: CookieService,
        private geoLocationService: GeoLocationService,
        private dialog: MatDialog,private http:HttpClient) {
    }

    ngOnInit() {
        this.canonicalService.setCanonicalURL();
        this.recallJsFuntions();
        this.title.setTitle('COMPLIANCEiNBOX');
        this.location = this.getGeoLocation();
        let c_flag = localStorage.getItem('c_flag');
        if (c_flag == null || c_flag == 'false') {
            this.showBanner = true;
            localStorage.setItem( "c_flag", "true");
        } else if(c_flag == 'true') {
            this.showBanner = false;
        }
         else {
            this.showBanner = c_flag ? true : true;
        }
        this.metaTagService.addTags([
            {
                name: 'keywords',
                content: 'Compliance management software, compliance management software, compliance software',
            },
            {
                name: 'description',
                content: ' CiB compliance management system ensures you to comply with industry and security standards with all top regulatory Compliance management software in India. ',
            }
        ]);
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                //$.getScript('/assets/js/custom.js');
                $('.preloader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    openAddDialog() {
        let dialogRef = this.dialog.open(this.cookieDialog);
    }

    getGeoLocation() {
        this.geoLocationService.getLocation().subscribe(
          (position: any) => {
            this.cookieData.longiTude = position.coords.longitude
            this.cookieData.latiTude = position.coords.latitude
            this.cookieData.accuracy = position.coords.accuracy
          },
          (error: any) => {
            console.error('Failed to get geolocation:', error);
            // Handle error appropriately
          }
        );
      }

    async acceptAll() {
        // const expirationDate = new Date();
        // expirationDate.setDate(expirationDate.getDate() + 30);
        // this.cookieService.set('user', encodeURI('this is only for testing purpose'), expirationDate);
        let data = this.cookieService.getAll();
        this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
            this.cookieData.ipAddress = res.ip;
            console.log(this.cookieData.ipAddress);
            console.log(res);
            this.cookieData.cookies = JSON.stringify(data);
            console.log(this.location);
            console.log(location);
            this.http.post(`${APIURL}/save`,this.cookieData)
            .subscribe({
                next: (data:any) => {
                    localStorage.setItem('c_flag',JSON.stringify(this.showBanner));
                    console.log(data);
                },
                error: error => {
                    console.error(error);
                }
            });
        });
       
        localStorage.setItem('c_flag','true');

        // this._toaster.success("Cookies saved succesfully for " + expirationDate + " days");
    }
}