import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DsarService } from 'src/app/services/dsar/dsar.service';


@Component({
  selector: 'app-dsar',
  templateUrl: './dsar.component.html',
  styleUrls: ['./dsar.component.scss']
})
export class DsarComponent implements OnInit {

  dsar:any;
  dsars:any[] = [];
  @ViewChild('feedBackDialog') feedBackDialog!:TemplateRef<any>

  constructor(
    private _dsar:DsarService,
    private _dialog:MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadAllDsar();
  }

  loadAllDsar() {
    this._dsar.getAllDsar().subscribe({
      next: (data:any) => {
        this.dsars = data;
        console.log(data);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  saveDsar() {
    this._dsar.saveDsar(this.dsar).subscribe({
      next: (data:any) => {
        this.dsars.push(data);
        console.log(data);
        // this._toaster.open("Your request has been successfully saved",'OK', {
        //   duration: 5000,
        // });
        this.opendiag();
      },
      error: error => {
        console.error(error);
      }
    });
  }

  opendiag() {
    let diagRef = this._dialog.open(this.feedBackDialog,{
      autoFocus: true,
    });
  }

  submitForm(formData:any) {
    this.dsar = formData;
    console.log(this.dsar);
    this.saveDsar();
  }

}

