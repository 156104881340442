<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>AMAR TUMPLWAR</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>AMAR TUMPLWAR</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Amar-T.jpg" alt="Images">
                
                
            </div>
            <div class="col-md-8 member_desc_div">
                <h3 class="member_name">AMAR TUMPLWAR</h3>
                <b>VICE PRESIDENT – BUSINESS DEVELOPMENT</b>
                <p class="member_desc">Amar is a Law Graduate, MBA in Finance and has worked in various multinationals for over three decades. He has been a part of the Boston Financial family for more than 10 years and has been piloting the increasing presence of the Boston Financial Advisory group in various Jurisdictions. Business Development, Identification of prospect and their engagement has been the core areas of his work.</p>
                <p>He has also strived towards setting up the business development back-office and effectively driving the sales force towards lead generation. Amar’s expertise is actively sought in identifying the marketing tools along with their effective deployment for entering new market segments. Amar has been instrumental in identifying sales channel partners and successfully integrating and expanding them into new geographies. He has also been our key player in forging alliances.</p>
                <p>As a part of untiring efforts, BFAG has now been able to enter the government sector.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

