import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DsarService } from 'src/app/services/dsar/dsar.service';

@Component({
  selector: 'app-dsar-view',
  templateUrl: './dsar-view.component.html',
  styleUrls: ['./dsar-view.component.scss']
})

export class DsarDataviewComponent implements OnInit {

  constructor(
    private _dsar:DsarService,
    private snackBar:MatSnackBar
  ) { }

  dsars:any[]=[];
  dataSource:any;

  ngOnInit(): void {
    this.loadAllDsar();
  }

  loadAllDsar(){
    this._dsar.getAllDsar().subscribe({
      next: (data:any) => {
        console.log(data);
        this.dsars = data;
      },
      error: error => {
        console.error(error);
        this.snackBar.open("Error occurs while loading data",'',{
          duration: 5000,
        })
      }
    });
  }

}
