<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>GANESH PADAKANNAYA</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>GANESH PADAKANNAYA</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Ganesh-P.jpg" alt="Images">
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>GANESH PADAKANNAYA</h3>
                <b>DIRECTOR & CO-FOUNDER</b>
                <p class="member_desc">Ganesh has been associated with the Boston Financial family since December 2009. He possesses in-depth knowledge of business valuations, business deals, and business structuring and management consultancy to the clients. Currently, acting as a COO at the firm, he is responsible for the management of end-to-end delivery of all F&A processes. He works extensively in developing the M & A acquisition and disposition model.</p>
                <p>He has gained experience over working with different jurisdictional GAAP, consolidation, etc., and has developed a keen eye for critically examining business models with different applicable legal setups. Coupled with experience in taxation and assurance, he has effectively carried out due diligence practice within the company.</p>
                <p>Ganesh’s main focus has always been in driving the development, sustenance, and continual improvement of robust operating frameworks for all the processes that support business outcomes and strategy. A highly result-oriented person with focus on planning. He is a Chartered Accountant and Law Graduate with 15 plus years of experience. </p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

