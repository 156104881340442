<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>UJJAL MUKHERJEE</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>UJJAL MUKHERJEE</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Ujjal-Mukherjee.jpg" alt="Images">
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>UJJAL MUKHERJEE</h3>
                <b>PRODUCT MANAGER</b>
                <p class="member_desc"> A seasoned Product Manager with 5+ years’ experience in end-to-end product and project management. Functional areas comprise of project scoping, execution, and delivery in close collaboration with various stakeholders including senior executives and cross-teams. </p>
                <p>Key competencies include project management, agile methodologies, identification and mitigation of bottlenecks, stakeholder management, etc. Also, ability to provide data-driven critical insights using statistical methods on Power BI and MS Excel.</p>
                <p>He manages the Design & Development of COMPLIANCE iNBOX.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

