import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import APIURL from 'src/app/helper/apiUrl';

@Injectable({
  providedIn: 'root'
})
export class DsarService {

  constructor(
    private http:HttpClient
  ) { }

  saveDsar(dsar:any) {
    return this.http.post(`${APIURL}/dsar/save`,dsar);
  }

  getAllDsar() {
    return this.http.get(`${APIURL}/dsar/getAll`);
  }
}