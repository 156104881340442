<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>VINOD KAINIKAT</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>VINOD KAINIKAT</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Vinod-K.jpg" alt="Images">
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>VINOD KAINIKAT</h3>
                <b>DIRECTOR & CO-FOUNDER</b>
                <p class="member_desc">Vinod is a commercially savvy and highly organized person with strong business acumen. In the last 18 years, he has worked with leading corporations and fortune 500 companies. He is an effective team builder and confident decision maker and has hands-on experience in strategic planning, business unit development, operational and financial management, project and product management, as well as in budget analysis and expense control.</p>
                <p>In his previous stints, he has showcased the ability to analyze an organization’s critical business requirements and corporate governance programs and has developed innovative and cost-effective improvement solutions.</p>
                <p>In his role as COO, he oversees and manages the delivery and business development for Avanze Group.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

