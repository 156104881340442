import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amit-mulgund',
  templateUrl: './amit-mulgund.component.html',
  styleUrls: ['./amit-mulgund.component.scss']
})
export class AmitMulgundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
