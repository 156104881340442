<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>RAMU RADHAKRISHNAN</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>RAMU RADHAKRISHNAN</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->


<div class="project-details-area team-details pt-60 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/img/team/Ramu-R.jpg" alt="Images">
                
                
            </div>
            <div class="col-md-8 member_desc_div">
                <h3>RAMU RADHAKRISHNAN</h3>
                <b>DIRECTOR & CHIEF PRODUCT OFFICER</b>
                <p  class="member_desc">Ramu Radhakrishnan is a seasoned professional with over 20 years of experience in product management and business transformation. He carries a rich expertise in technology products and services spanning Mortgage and healthcare domains.</p>
                <p>Prior to his current role, Ramu spearheaded a team of over 100 professionals in Mortgage and Learning divisions of Indecomm Global Services. Throughout his career, Ramu has a proven track record of building strategic solutions for the U.S. mortgage industry and has gained extensive experience in mortgage lifecycle.</p>
                <p>As Chief Product Officer at Avanze Group, Ramu is responsible to ensure that the technology solutions are effectively used to improve, optimize, and enhance the client services and workflows. Ramu leads the product development from conceptualization to its launch and builds innovative features and automation to improve efficiency and scalability of the products. Ramu also heads the R&D team to focus on newgen technologies – Artificial Intelligence, RPA, and Test Automation.</p>
                <p>Ramu is an Engineering graduate from Cochin University.</p>
                <!--<ul class="social-link">
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                </ul>-->
            </div> 
        </div>
    </div>
</div>

