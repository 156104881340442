import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }

  latestBlogData(data:any) {
    return this.http.post(`${environment.baseUrl}latest_blog`, data);
  }

  categoryData(data:any) {
    return this.http.post(`${environment.baseUrl}fetch_category`, data);
  }

  blogData(data:any) {
    return this.http.post(`${environment.baseUrl}view_blog`, data);
  }

  blogDetailData(data:any) {
    return this.http.post(`${environment.baseUrl}view_blog_detail`, data);
  }


}
