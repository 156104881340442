<h3 class="text-center">Information Security Management System</h3>
<p class="text-center">Boston Financial Advisory Group is ISO 27001:2013 certified company. Where we maintain CIA Triad
    (Confidentiality, Integrity and Availability).</p>
<p class="text-center">We maintain a clear framework of Information Security Management Process and key operational elements
    such as</p>
<p>&nbsp;</p>
<!-- Choose Area -->


<div class="choose-area-two choose-bg pt-40">
    <div class="container">
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Keeping IT System up to date</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Information Security Policies</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Background Verification of Employees</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Anti-Virus Protection</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Data Storage & Backups</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> IT Change Management</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Regular Information Security Training</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Regular Internal Audits</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Assets Management</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Information Classification</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Access Control & Access Rights</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Physical & Environmental Security</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Information Security Risk Assessment & <br />
                                Risk Treatment</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Protection of PII (Personally Identifiable<br />
                                Information)</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Network Security</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Regular VAPT (Vulnerability Assessment <br /> and
                                Penetration Testing)</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Event Logging</p>
                        </div>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Dedicated CISO (Chief Information Security
                                Officer)</p>
                        </div>
                        <div class="clearfix"></div>
                        <p>&nbsp;</p>
                        <p>ISO 27001 certificates may be requested via by writing to <a
                                href="mailto:privacy@bostonfagroup.com"> privacy@bostonfagroup.com</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
