<!-- Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blogs</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blogs</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Area Two -->
<div class="blog-area-two pt-40 pb-40">
    <div class="container">
        <!--<div class="section-title text-center">
            <span>Case Study</span>
            <h2>Categories</h2>
        </div>-->
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="side-bar-wrap">
                    <!--<div class="search-widget">
                        <form class="search-form">
                            <input type="search" class="form-control" placeholder="Search...">
                            <button type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>-->

                    <div class="side-bar-widget">
                        <!--<h3 class="title">Categories</h3> -->

                        <div class="side-bar-categories">
                            <ul>
                                <li *ngFor="let category of categoryList;  let i =index">
                                    <a (click)="getBlogList(category?.category_name)" style="cursor: pointer;" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{category?.category_name}} </a>
                                    <!--<span>(1)</span>-->
                                </li>
                                
                            </ul>
                        </div>

                        
                    </div>
                </div>
            </div>        
        </div>
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    
                    <div class="col-lg-4 col-md-6" *ngFor="let blog of blogList | paginate:  { itemsPerPage: 6, currentPage: p };  let i =index;  trackBy: trackByFn">
                        <div class="blog-card">
                            <a (click)="blogDetails(blog?.blog_id,blog?.blog_name)" style="cursor: pointer;" ><img src="{{blog?.blog_image}}" alt="Images"></a>
                            <div class="content">
                                <h3><a (click)="blogDetails(blog?.blog_id,blog?.blog_name)" style="cursor: pointer;" >{{blog?.blog_name | slice:0:44}}...</a></h3>
                                <!--<ul>
                                    <li>By <a routerLink="/blog-1">{{blog?.created_by}} </a> /</li>
                                    <li><span>20</span> COMMENTS /</li>
                                    <li><span>28</span> VIEWS</li>
                                </ul>-->
                                <p>{{blog?.short_desc | slice:0:120 }}... </p>
                                <a (click)="blogDetails(blog?.blog_id,blog?.blog_name)" style="cursor: pointer;"  class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <div class="row text-center">
                    <pagination-controls (pageChange)="selPag($event)"></pagination-controls>
                </div>
            </div>

           
        </div>
    </div>
</div>
<!-- Blog Area Two End -->