import { Component, OnInit } from '@angular/core';
import { CasestudyService } from 'src/app/services/casestudy.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {
  id:any;
  caseDetails:any;
  result:any;

  constructor(
    private _casestudyService: CasestudyService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.id = this._activatedRoute.snapshot.params.name;
    console.log(this.id);
    this.getCaseStudyDetails(this.id);
  }

  getCaseStudyDetails(id:any) {
    const formData = new FormData();
    formData.append('title', id);

    this._casestudyService.casestudyDetails(formData).subscribe(res => {
      this.result = res;
      this.caseDetails = this.result.data;
      console.log(this.caseDetails);
    })
  }

  

}
