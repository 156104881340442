import { BrowserModule,  } from '@angular/platform-browser';
import { CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
// import {TrustCenterComponent} from './components/pages/trust-center/trust-center.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule} from '@angular/material/button'
import {CookieService} from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeComponent } from './components/pages/home/home.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { SameerPaddalwarComponent } from './components/pages/team/sameer-paddalwar/sameer-paddalwar.component';
import { GaneshPadakannyaComponent } from './components/pages/team/ganesh-padakannya/ganesh-padakannya.component';
import { PradeepKumarVComponent } from './components/pages/team/pradeep-kumar-v/pradeep-kumar-v.component';
import { VinodKainikatComponent } from './components/pages/team/vinod-kainikat/vinod-kainikat.component';
import { RamuRadhakrishnanComponent } from './components/pages/team/ramu-radhakrishnan/ramu-radhakrishnan.component';
import { AmarTumplwarComponent } from './components/pages/team/amar-tumplwar/amar-tumplwar.component';
import { UjjalMukherjeeComponent } from './components/pages/team/ujjal-mukherjee/ujjal-mukherjee.component';
import { BlogsComponent } from './components/pages/blogs/blogs.component';
import { RefundCancellationPolicyComponent } from './components/pages/refund-cancellation-policy/refund-cancellation-policy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTimepickerModule } from 'mat-timepicker';
import { DatePipe } from '@angular/common';
import { AmitMulgundComponent } from './components/pages/team/amit-mulgund/amit-mulgund.component';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component';
import { TrustCenterComponent } from './components/pages/trust-center/trust-center.component';
import { DsarComponent } from './components/pages/dsar/dsar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginDialogComponent } from './components/pages/login-dialog/login-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSnackBarModule } from '@angular/material/snack-bar'
import { CookiesPolicyComponent } from './components/pages/cookies-policy/cookies-policy.component';
import { IsoPageComponent } from './components/pages/iso-page/iso-page.component';
import { CcpaPageComponent } from './components/pages/ccpa-page/ccpa-page.component';
import {MatTableModule} from '@angular/material/table';



@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    BlogDetailsComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ErrorComponent,
    CaseStudyComponent,
    AboutComponent,
    ContactComponent,
    ClientsComponent,
    HomeComponent,
    CaseStudiesComponent,
    SameerPaddalwarComponent,
    GaneshPadakannyaComponent,
    PradeepKumarVComponent,
    VinodKainikatComponent,
    RamuRadhakrishnanComponent,
    AmarTumplwarComponent,
    UjjalMukherjeeComponent,
    BlogsComponent,
    RefundCancellationPolicyComponent,
    AmitMulgundComponent,
    ThankYouComponent,
    TrustCenterComponent,
    DsarComponent,
    LoginDialogComponent,
    CookiesPolicyComponent,
    IsoPageComponent,
    CcpaPageComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatSnackBarModule,
    MatTableModule,
    HttpClientModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatTimepickerModule,
    MatCheckboxModule,
    MatDialogModule
  ],
  providers: [DatePipe,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
