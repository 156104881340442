import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(
    private router:Router,
    private _activatedRoute:ActivatedRoute,
    private titleService: Title, 
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('CiB Best Workflow Management Software for Firm | Clients ');
    this.metaTagService.updateTag( { name:'keywords', content:'Best Workflow Management Software '},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'CiB is the best workflow management software for CA, Its comes with a powerful set of features Like: Auditing Management, Regulatory Compliance Management.'},"name='description'");
    
  }

}
