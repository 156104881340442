<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-40 pb-40">
    <div class="container">
        <div class="section-title text-center">
            <h2 class="about-title">“When Finance and Technology come together”</h2>
        </div><br>
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    <h3 style="margin-bottom: 10px;">About COMPLIANCE iNBOX (CiB) :</h3>
                    <p style="margin-bottom: 10px;">BFAG promoters having worked in global organisations with presence in multiple jurisdictions always felt the need for such compliance management tools that can help the stakeholders, CXO’s of MSME organisations to keep control over global compliances, track – monitor them and ensure complete compliance. The idea was conceptualised but finding a dependable technology partner who could help in transforming the idea from concept to product was a challenge.  </p>
                    <p style="margin-bottom: 10px;">BFAG & AVANZE came together to jointly bring CiB and the most efficient compliance management system into reality. Through CiB, you can focus on:   </p>
                    <div class="col-md-4 about-middle">
                        <p style="text-align: center;margin-bottom: 5px;font-weight: bold;">Global Compliance Management</p>
                    </div>   
                    <div class="col-md-3 about-middle">
                        <p style="text-align: center;margin-bottom: 5px;font-weight: bold;">Resource Engagement and Activity Tracking</p>
                    </div>   
                    <div class="col-md-4 offset-1 about-middle">
                        <p style="text-align: center;margin-bottom: 5px;font-weight: bold;">E- Audit with PEER Review and much more</p>
                    </div>   
                    <div class="clearfix"></div>
                    <p style="margin-bottom: 10px;">Thus, making a difference to the lives of MSME corporates & their stakeholders, Chartered Accountants - Certified Public Accountants – Audit Firms, PRO Firms, CXO’s.</p>
                    <p style="margin-bottom: 10px;">With CiB’s Compliance and Risk Management, get a quick bird’s eye view of all the activities, never miss a compliance filling deadline, instant & secure data sharing with auditors. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <img src="assets/img/about/CIB.jpg" class="img-fluid  about_cib_img" alt="Images">
            </div>
        </div>

        <div class="row align-items-center mbp-4">
            <div class="col-lg-4 col-md-12">
                <div class="">
                    <img src="assets/img/about/Boston.jpg" class="img-fluid  about_bfag_img" alt="Images">
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    <h3>About Boston Financial Advisory Group (BFAG) :</h3>
                    <p>“BFAG – Outsourcing, Consulting, Technology. Powered by Business Intelligence”</p>
                    <p>Boston Financial Advisory Group is a team of Certified Public Accountants, Chartered Accountants, Corporate Secretaries, MBAs with decades of experience and expertise in Global Corporate Structuring, Cross Border Advisory, Consolidation, GAAP conversion, M & A, Valuation, Due Diligence, Financial Accounting, Reporting, Compliance, Payroll. BFAG believes in providing F & A solution to the SMEs worldwide thru our technology backed, innovative and personalized approach.</p>
                    <p>With 127+ experts spread over 4 locations globally, we are a one stop solution that helps transform businesses into an agile organisation that can outperform and outcompete. We help you set-up wherever you go.</p>

                </div>
            </div>
        </div>


        <div class="row align-items-center mbp-4">
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    
                   <h3>About AVANZE Group :</h3>
                   <p>“AVANZE means advancing with purpose”.</p>
                    <p>Founded by entrepreneuring leaders with rich experience in business management, Avanze is a technology company focused on providing products and services to the clients from the Banking, Financial Services, and Insurance (BFSI) industry. The company is built on thought leadership and innovation. Avanze believes in a collaborative approach to provide transformational solutions firmly grounded on creativity, technology implementation and strategic partnership.</p>
                    <p>With 1000+ exceptional people in over 4 centres globally; Avanze helps you gain competitive advantage by implementing industry best practices, integrating business intelligence with cutting-edge technology to drive business values and growth.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="">
                    <img src="assets/img/about/Avanze.jpg" class="img-fluid about_img_shadow about_anz_img" alt="Images">
                </div>
            </div>
        </div>


        

    </div>
    <!--<div class="about-polygon-shape">
        <img src="assets/img/shape/shape10.png" alt="Images">
    </div>-->
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-40">
    <div class="container">
        <div class="row my-list">
            <div class="col-lg-12 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>Who Can Leverage?</h2>
                        <p>Any person or organization which needs to streamline and have complete control over compliance management, resource engagement, audit, data security. In short, below persons should use COMPLIANCE iNBOX</p>
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Chief Financial Officer and CXOs</p>
                        </div>   
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Auditors and Corporate Secretaries</p>
                        </div>   
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i> Promotors, Partners, Stakeholders</p>
                        </div>  
                        <div class="col-md-4 about-middle">
                            <p><i class='bx bx-check'></i>PRO Firms</p>
                        </div>   
                        <div class="clearfix"></div>
                        <p>With the E-audit feature, the audit will be over in a jiffy. The auditor can have complete view and control over the audit activities plus the stake holders would be aware of audit progress at any given moment of time thus ensuring timely completion of audit activity.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->


<!-- Choose Area -->
<div class="pt-40 pb-40">
    <div class="container">
        <div class="row my-list">
            <span class="text-center">Our</span>
            <h2 class="text-center">Strengths</h2><br><br>
            <p>As everyone around the globe becomes increasingly dependent on technology, industry standards and legal requirements are intensifying. Because non-compliance can result in fines, security lapses, and harm to your company's brand, compliance management is crucial. Regulatory compliance and risk management make sure your company stays in line with the most recent regulations and assist in preventing business disruption and efficient audit management. </p>
            <h6>With CiB you can</h6><br>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Audit made easy thru customized Audit Plans – Checklists – Tracker</p>
            </div>   
            
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Have bird’s-eye-view through a customizable Dashboard</p>
            </div>
            
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Define, Assign & Manage the Workflows</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Do Resource Engagement & Activity tracking</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Track the Due dates with Automatic Alerts, Reminders & Notifications</p>
            </div>
            
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Hosted on AWS, safe & secure data sharing ensures complete control over data</p>
            </div>
            
            <br><br><br>
            <h6>Outcome for Business</h6><br>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Minimal follow ups and reduced time to manage compliance activities</p>
            </div>   
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Ensures faster response to the dynamic regulatory environment</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Comprehensive compliance coverage</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right"> Resource engagement and Activity management ensuring complete control</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Increased efficiency and reduced compliance costs</p>
            </div>
            <div class="col-lg-4">
                <div class="os-left">
                    <i class='bx bx-check'></i>  
                </div>
                <p class="os-right">Automated workflows, Standard File Folder structure, reduce person dependency</p>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Benefits -->
<div class="pt-50 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>How is CiB best Practice Management Software for CA?</h2>
                    <p>Accounting organizations are based on intricate and thorough financial transactions and duties that are meticulously completed by qualified accountants. The correct practice management software features are essential for accountants to stay current and on top of their game in the current fast-paced world. To help CAs ace their compliance practice and audit practice, CiB brings to them the most efficient and effective CA practice management software. This software is designed to help them stay updated and proactive when it comes to audit management and compliance filing to avoid any form of delay or penalty possibilities.</p>
                    <p><b>Here is how CiB’s Practice Management Software for CA can help you-</b></p>
                    <p>Accountants Practice Management Software does more than just provide a simple to-do list.</p>
                    <p>CiB’s CA practice management software offers features that go above and beyond the to-do list that is displayed by certain standard back-office management software. It provides a better task management system that successfully manages features like easy and simple task allocation, due date tracking, intuitive team tracking from anywhere at any time, scheduling tasks for a team or a specific employee, etc., so the company never misses out on a step in the process and to keep live track of the firm's performance.</p>
                    <p>Resolve a wide range of functions with CA practice management software than a straightforward filing cabinet.</p>
                    <p>For the team to collaborate on a drive and for the user to have easy access to the data from any location, a decent Accountants Practice Management Software hosts an effective cloud-based data management feature. Features like permission control, activity tracking, device control, and user control are offered by secure cloud-based support.</p>
                    <p>CiB offers a better database administration and data security as a Practice Management Software for CAs.</p>
                    <p>Given that accountants handle a lot of sensitive and important client information that cannot be compromised, data security is one of the most important features of CiB’s CA practice management software. To prevent any potential data breach, we restrict access to this crucial data based on user credentials, security clearance.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Benefits -->

<!-- Services Area -->
<div class="choose-area services-area pt-40 pb-40">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="section-title text-center" style="margin-bottom: 4%;">
                    <span>Our</span>
                    <h2>Key Features</h2>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a >Dashboard</a></h3>
                    <p>Get a comprehensive view on single screen, know the status of various compliance and audit activities, evaluate vital reports in the form of charts, graphs, data grids.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card2">
                    <a  class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a >Workroom</a></h3>
                    <p>Now assign, monitor, review the progress, ensure Resource Engagement with Role-based access to stakeholders, resources thru encrypted users id and passwords.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card3">
                    <a class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a >Dataroom</a></h3>
                    <p>Data security no longer a concern. CiB has a safe and secure data-sharing facility with integrated document management, access based permanent repository.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card4">
                    <a class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a >E-Audit</a></h3>
                    <p>Create and publish Audit plans, track the same with our Audit Plan Tracker. Quickly access the previous year's data from repository which has a customizable standard file folder structure which eliminates resource dependency. Peer review feature.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card5">
                    <a class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a >Compliance Calendar</a></h3>
                    <p>Receive timely updates on compliance with our automated alerts, triggers & notifications. View all upcoming compliance events and their due dates. You can set the frequency for sending SMS, email reminders.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card6">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Notification & Alerts</a></h3>
                    <p>Stay updated at all times with the notifications and alerts feature. Integration with regulatory sites ensures quick notifications that can be shared with all concerned.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card7">
                    <a r class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a >Chatroom</a></h3>
                    <p>Integrated chatroom facility with history ensures a quick reference point, Chatroom  also enables you to send & receive messages, share documents. </p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card8">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Coverage</a></h3>
                    <p>CiB covers all applicable laws such as Direct & Indirect Tax, Labour Laws, State, and federal compliances, Corporate Laws.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-card9">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Financial Analytics</a></h3>
                    <p>CiB is Integrated with financial accounting software. You can now view/access all real time financial reports through this feature.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>

        <!--<div class="service-view-btn text-center">
            <a routerLink="/services-1" class="view-btn">View More</a>
        </div>-->
    </div>
</div>
<!-- Services Area End -->


<!-- Solutions Area -->
<!--<div class="solutions-area pt-50 pb-30">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content">
                    <div class="section-title">
                        
                        <h2>I want to</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contacts" class="default-btn">Schedule Demo</a>
                    <button type="button" class="default-btn" data-bs-toggle="modal" data-bs-target="#myModal">
                        Schedule Demo
                    </button>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="solutions-btn">
                    <a routerLink="/contacts" class="default-btn"  style="width: 67%;text-align: center;">Free Trial</a>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- Solutions Area End -->

<!-- The Modal -->
<div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Modal Heading</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <div class="contact-form">
            <form [formGroup]="contact" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" formControlName="name" [ngClass]="{'is-invalid': submitted && f.name.errors }" class="form-control" placeholder="Name*">
                            <div *ngIf="submitted && f.name.errors" class="text-danger small err_msg">
                                <div *ngIf="f.name.errors?.required">Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" formControlName="email_id" [ngClass]="{'is-invalid': submitted && f.email_id.errors }" class="form-control" required placeholder="Email*">
                            <div *ngIf="submitted && f.email_id.errors" class="text-danger small err_msg"
                                [ngClass]="{'is-invalid': submitted && f.email_id.errors }">
                                <div *ngIf="f.email_id.errors?.required">Email is required</div>
                                <div *ngIf="f.email_id.errors?.email">Please enter vaild email Id.</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" pattern="\d*" maxlength="15" formControlName="mobile" class="form-control" placeholder="Mobile Number*">
                            <div *ngIf="submitted && f.mobile.errors" class="text-danger small err_msg"
                                [ngClass]="{'is-invalid': submitted && f.mobile.errors }">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors?.pattern">Invalid mobile number</div>
                            </div>    
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                           
                            <input type="text" formControlName="organization" [ngClass]="{'is-invalid': submitted && f.organization.errors }" class="form-control" placeholder="Organization Name*">
                            <div *ngIf="submitted && f.organization.errors" class="text-danger small err_msg">
                                <div *ngIf="f.organization.errors?.required">Organization is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea class="form-control" formControlName="message" cols="30" rows="8"  placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 ">
                        <mat-form-field style="background-color: #f8f9fb;border: 1px solid #ebebeb;border-radius: 0.25rem;height: 61px;
                        line-height: 2;
                        padding-left: 15px;">
                            <input matInput [matDatepicker]="picker" formControlName="schedule_dt" placeholder="Choose Date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker ></mat-datepicker>
                            <mat-error *ngIf="f.schedule_dt.hasError('required')"> Schedule Date is required</mat-error>
                          </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Choose Time*</mat-label>
                        
                            <!-- The timepicker input -->
                            <input matTimepicker #t="matTimepicker"  [strict]="false" id="timepicker-example" mode="24h"
                            formControlName="schedule_time" >
                            <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
                            <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon>
                            <mat-error *ngIf="f.schedule_time.hasError('required')"> Schedule Time is required</mat-error>
                          </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-md-12 text-center">
                        <button type="submit" class="default-btn contact-page-btn">Schedule Demo</button>
                    </div>
                    <!--<div class="col-lg-6 col-md-12 text-center">
                        <a routerLink="/contacts" class="default-btn"  style="width: 100%;text-align: center;">Free Trial</a>
                    </div>-->
                </div>
            </form>
        </div>
        </div>
  
        
  
      </div>
    </div>
  </div>
  