<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:Info@complianceinbox.com">Info@complianceinbox.com</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-phone'></i>
                                </div>
                                <a href="tel:+918600477799" target="_blank">+91 86004 77799</a> /<span><a href="tel:+917488416447" style="margin-left: 5px;">+91 74884 16447</a></span> /<span><a href="tel:+16095478495" style="margin-left: 5px;">+1 609 547 8495</a></span> /<span><a href="tel:+971568554250" style="margin-left: 5px;">+971 56 855 4250</a></span>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 mobile_none">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li class="top-social-link-linkedin"><a href="https://www.linkedin.com/company/compliance-inbox" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li class="top-social-link-facebook"><a href="https://www.facebook.com/complianceinbox" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li class="top-social-link-twitter"><a href="https://twitter.com/CiB_BAFINTECH" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <!--<li class="top-social-link-instagram"><a href="https://www.instagram.com/complianceinbox/" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
                            <li class="top-social-link-youtube"><a href="https://www.youtube.com/channel/UCLdQZ0AG_GuQ6uiQiRy926A" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Product</a></li>
                    <li class="nav-item"><a routerLink="/clients" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Clients</a></li>
                    <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>
                    <!--<li class="nav-item"><a (click)="getBlogList(0,0)" style="cursor: pointer;"  class="nav-link" [class.active]="active" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >Blogs</a></li>-->
                    <li class="nav-item"><a routerLink="/blogs/compliance/" style="cursor: pointer;"  class="nav-link" [class.active]="active" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >Blogs</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    <li class="nav-item"><a href='https://ciblogin.com/Login' target="_new" >Login</a></li>
                    <li class="nav-item"><a (click)="goDown1()" class="btn header-btn">Schedule a Demo</a></li>
                    <li class="nav-item"><a href='#' class="btn header-btn" >Buy Now</a></li>
                    
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home</a></li>
                    <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">About Products</a></li>
                    <li class="nav-item"><a routerLink="/clients" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Clients</a></li>
                    <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Case Studies</a></li>
                    <!--<li class="nav-item"><a (click)="getBlogList(0,0)" style="cursor: pointer;" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Blogs</a></li>-->
                    <li class="nav-item"><a routerLink="/blogs/compliance/" style="cursor: pointer;" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Blogs</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact</a></li>
                    <li class="nav-item"><a  href='https://ciblogin.com/Login' target="_new" class="nav-link" >Login</a></li>
                    <!-- Your existing HTML code -->
                    <!-- <button (click)="openLoginDialog()">Login</button> -->
                    <li class="nav-item"><a  href='https://ciblogin.in/TrialOrg/request' target="_new" class="nav-link" >Free Trial</a></li>
                    <li class="nav-item"><a  href='#' class="nav-link" >Buy Now</a></li>
                </ul>
                <div class="others-options d-flex align-items-center">
                    
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->
