<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{caseDetails[0].case_name}}</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Case Study Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Details Area -->
<div class="project-details-area pt-70 pb-40">
    <div class="container">
        <div class="project-article">
            <div class="blog-article-img">
                <img src="{{caseDetails[0].case_image_dt}}" alt="Images">
            </div>

            <div class="project-status">
                <div class="row">
                    <div class="col-lg-7 col-sm-7">
                        <ul>
                            <li>Written by <a routerLink="/">{{caseDetails[0].created_by}}</a></li>
                            <li>{{caseDetails[0].created_dt | date }}</li>
                        </ul>
                    </div>

                    <div class="col-lg-5 col-sm-5">
                        <div class="project-comment">
                            <!--<h3><i class='bx bxs-message-detail'></i> 32 Comments</h3>--> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="project-content ">
                <h2>{{caseDetails[0].case_name}}</h2>
                
                <div [innerHTML]="caseDetails[0].description">
                                      
                </div>   
            </div>

            

        </div>
    </div>
</div>
<!-- Project Details Area End -->


<!-- Project Area -->
