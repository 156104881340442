import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BlogService } from 'src/app/services/blog.service';
import { ContactService } from 'src/app/services/contact.service';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/shared/canonical.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  submitted = false;
  resultFalse = false;
  resultTrue = false;
  result:any;
  contact: any;
  safeurl: SafeResourceUrl | undefined;
  video_link:any;
  latestblogList:any;
  minDate = new Date();

  showShortDesciption = true;
  showShortDesciption1 = true;
  showShortDesciption2 = true;
  showShortDesciption3 = true;

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption
  }

  alterDescriptionText1() {
    this.showShortDesciption1 = !this.showShortDesciption1
  }

  alterDescriptionText2() {
    this.showShortDesciption2 = !this.showShortDesciption2
  }

  alterDescriptionText3() {
    this.showShortDesciption3 = !this.showShortDesciption3
  }

  defaultValue= {hour: 13, minute: 30};
  

  timeChangeHandler(event: Event) {
    console.log(event);
  }

  invalidInputHandler() {
    // some error handling  
  }

  constructor(
    public sanitizer: DomSanitizer, 
    private router:Router,
    private _activatedRoute:ActivatedRoute,
    private formBuilder: FormBuilder,
    private _blogService: BlogService,
    private _contactService: ContactService,
    public datepipe: DatePipe,
    private titleService: Title, 
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.contactForm();
    this.getLatestBlog();

    /*this.metaTagService.addTags([
      {
        name: 'keywords',
        content: 'compliance management system, audit management system, compliance management software, compliance tools, compliance software, ca practice management software, regulatory compliance management tools, regulatory compliance management software, compliance software solutions, Compliance and Tax management',
      },
      {
          name: 'description',
          content: 'You stay compliant while CiB monitoring and assessing systems to ensure you comply with industry and security standards, as well as corporate and regulatory policies & requirements.',
        }
    ]);*/
    this.titleService.setTitle('CiB Best Compliance management software | Compliance Inbox');
    this.metaTagService.updateTag( { name:'keywords', content:'Compliance management software'},"name='keywords'");
    this.metaTagService.updateTag( { name:'description', content:'  CiB compliance management system ensures you to comply with industry and security standards with all top regulatory Compliance management software in India. '},"name='description'");
    
  }

  toTop() {
    document.getElementById("enq-form").scrollIntoView();
  }

  get f() { return this.contact.controls; }

  contactForm() {
    this.video_link = "assets/video/home_video.mp4";
    this.safeurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_link);

    this.contact = this.formBuilder.group({
      name: ['', Validators.required],
      email_id: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      organization: ['', Validators.required],
      message: [''],
      schedule_dt: ['', Validators.required],
      schedule_time: ['', Validators.required],
      agreeTerms: [false, Validators.requiredTrue] 
    })
  }

  getLatestBlog() {
    const formData = new FormData();
    formData.append("api_token", "Comp@ibx!#");

    this._blogService.latestBlogData(formData).subscribe(res => {
      this.result = res;
      this.latestblogList = this.result.data;
      console.log(this.latestblogList);
    })
  }


  onSubmit() {
    //this._router.navigate(['dashboard']);
    
    this.submitted = true;
    if (this.contact.invalid) {
      return;
    }
    else {
      const formData = new FormData();
      

      let name = this.contact.value.name;
      let email_id = this.contact.value.email_id;
      let mobile = this.contact.value.mobile;
      let organization = this.contact.value.organization;
      let message = this.contact.value.message;

      let scheduledt = this.contact.value.schedule_dt;
      let dateFormat1 = this.datepipe.transform(scheduledt, 'dd-MM-yyyy');

      let scheduletime = this.contact.value.schedule_time;
      let schedule_time = this.datepipe.transform(scheduletime, 'hh:mm');

      formData.append('name', name);
      formData.append('emailid', email_id);
      formData.append('mobile', mobile);
      formData.append('organization', organization);
      formData.append('message', message);
      formData.append('schedule_dt', dateFormat1);
      formData.append('schedule_time', schedule_time);
      
      

     // console.log(formData);return false;

      this._contactService.homecontactData(formData).subscribe(res => {
        this.router.navigate(['thank-you'], { relativeTo: this._activatedRoute });
       /* let response:any = res;
           
        if(response['result'] == 'F') {
          this.resultFalse = true;
          this.resultTrue = false;
       
        } else {  
          this.resultFalse = false;
          this.resultTrue = true;
          this.contact.reset();
          this.submitted = false;
          
        }*/
       
      })
    }

  }


  blogDetails(id:any, title:any) {
    debugger;
    let slug = title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      //.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');

     // console.log(slug);return false;
    this.router.navigate(['/blog-details',  slug], { relativeTo: this._activatedRoute })
  }

  getBlogList(id:any, title:any) {
    let slug = '';
    this.router.navigate(['/blogs/',  slug], { relativeTo: this._activatedRoute })
  }
  
}
