<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Case Study</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Case Study</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Case Area -->
<div class="case-area pt-40 pb-40">
    <div class="container">
        <!--<div class="section-title text-center">
            <span>Case Study</span>
            <h2>What Type of Work We Have Done</h2>
        </div>-->

        <div class="row pt-45">

            <div class="col-lg-4 col-md-6" *ngFor="let casestudy of casestudyList | paginate:  { itemsPerPage: 6, currentPage: p };  let i =index;  trackBy: trackByFn">
                <div class="case-card">
                    <a (click)="caseDetails(casestudy?.case_id,casestudy?.case_name)" style="cursor: pointer;" ><img src="{{casestudy?.case_image}}" alt="Images"></a>
                    <div class="content">
                        <h3><a (click)="caseDetails(casestudy?.case_id,casestudy?.case_name)" style="cursor: pointer;" >{{casestudy?.case_name | slice:0:44}}...</a></h3>
                        <p>{{casestudy?.short_desc | slice:0:134}}...</p>
                        <a (click)="caseDetails(casestudy?.case_id,casestudy?.case_name)" style="cursor: pointer;" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>   
            </div>
           
            
        </div>
    </div>
</div>
<!-- Case Area End -->