<style>
    header {
  position: relative;
  /*background-color: black;*/
  /*height: 567px; min-height: 25rem;*/ width: 100%;overflow: hidden;
}

header video {
  position: relative;top: 0%; left: 0%;min-width: 100%; min-height: 100%; width: auto; height: 100%; z-index: 0;
  -ms-transform: translateX(0%) translateY(0%);
  -moz-transform: translateX(0%) translateY(0%);
  -webkit-transform: translateX(0%) translateY(0%);
  transform: translateX(0%) translateY(0%);
  /*max-height: 550px;*/
 /* position: relative;top: -41%;  left: 0%;  right: 0%;  width: 100%;  z-index: 0;*/
}

header .container {
  position: relative; z-index: 2;
}

header .overlay {
  position: absolute;top: 0;left: 0; height: 100%; width: 100%;
  /*background-color: black;*/
  opacity: 0.5; z-index: 1;
}
.carousel-control-prev, .carousel-control-next{
    width: 7%!important;
}
.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon{
    filter: drop-shadow(0px 0px 0px white)!important;
}

@media only screen and (max-width: 767px) {
    header {
        position: relative;
        /*background-color: black;*/
        height: auto!important; min-height: inherit!important; width: 100%;overflow: hidden;
    }
    header video {
        max-height: auto!important;
    }
    .carousel-dark .carousel-indicators{
        display: none;
    }
}

</style>


<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
    </div>
    <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="10000">
            <header>
                <div class="overlay"></div>
                <video class="img-fluid"  loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                    <source [src]="safeurl" type="video/mp4">
                </video>
            </header>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
            <img src="assets\img\banner\CA-CPA.jpg" class="ban_img" alt="E-Audit through Compliance Inbox">
        </div>
        <div class="carousel-item"  data-bs-interval="5000">
            <img src="assets\img\banner\MSME-CFO.jpg" class="ban_img" alt="MSME-CFO">
        </div>
        <div class="carousel-item"  data-bs-interval="5000">
            <img src="assets\img\banner\PRO-Firms.jpg" class="ban_img" alt="PRO Firms">
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  

<!-- Client Area 
<div class="client-area-two ptb-40">
    <div class="container">
        
        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <img src="assets/img/client/avanze_group.PNG" alt="Images">
            </div>

            <div class="client-card">
                <img src="assets/img/client/cazaayan.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/centric.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/compito.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/diligent.PNG" alt="Images">
            </div>
        </div>
    </div>
</div>-->
<!-- Client Area End -->


<!-- About Area -->
<div class="about-area pt-40 pb-40">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 home_abtlt mobile_none">
                <div class="about-img">
                    <img src="assets/img/home/Aboutus-home.jpg" class="img-fluid home_about_big" alt="Compliance tools">

                    <div class="about-img-small">
                        <img src="assets/img/home/AboutHus-home2.jpg" class="img-fluid home_about_small" alt="Compliance tools">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content abtcnt_home">
                    <span>Know More</span>
                    <h1>About COMPLIANCE iNBOX (CiB)</h1>
                    <h3>Compliance Management Software Overview</h3>
                    <p>Compliance Management is an on-going & time-consuming process. CiB as a compliance management software helps you automate and manage multiple taxes, several laws to be complied with, multiple filings with differing dates, different calculation methodologies. We help you pay attention to ever-changing regulatory compliance landscape, increasing expectations from regulators to assist you in creating a culture of trust, integrity, transparency, pressure from stakeholders to reduce costs and improve on operational efficiencies.</p>
                    

                    <!--<div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>300+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>30+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>5000+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div>
                    </div>-->

                    <div class="about-btn">
                        <a routerLink="/about-us" class="learn-btn">Learn More</a>
                        <a (click)="toTop()" style="cursor: pointer;" class="get-btn">Schedule Demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Video Area -->
<div class="video-area video-bg1">
    <div class="container">
        <div class="row">
            <!--<div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="video-play-btn popup-btn"><i class="flaticon-play-button"></i></a>
                </div>
            </div>-->

            <div class="col-lg-12 col-md-12">
                <div class="video-content">
                    <div class="section-title text-center">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="guidelines-area">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <iframe class="embed-responsive-item" width="100%" height="460px" src="https://www.youtube.com/embed/al4vMW_wDhg" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Video Area End -->



<!-- Choose Area -->
<div class=" pt-50 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="section-title">
                    <!--<span>Why Choose Us</span>-->
                    <h2>Why do you need a Compliance Management System, CiB?</h2>
                    <p>Some organizations believe that compliance requires them to complete a number of tasks and still live with uncertainty. Improper processes and tools, leave a lot of room for error and possibilities for large fines. CiB is an integrated SaaS based solution that provides end-to-end compliance management and monitoring of all the Compliance Events and Activities within the organization.</p>
                    <p>Here is why your firm needs CiB:</p>    
                    <ul>
                        <li>Audit made easy thru customized Audit Plans – Checklists – Tracker.</li>
                        <li>Have bird’s-eye-view through a customizable Dashboard.</li>
                        <li>Define, Assign & Manage the Workflows.</li>
                        <li>Do Resource Engagement & Activity tracking.</li>
                        <li>Track the Due dates with Automatic Alerts, Reminders & Notifications.</li>
                        <li>Have safe & secure Data Sharing with complete control over data.</li>
                        <li>Hosted on AWS, have a 99.99% uptime.</li>
                    </ul>
                </div>

                
            </div>

            <div class="col-lg-5 col-md-12 solution_img_div mobile_none">
                <div class="choose-img">
                    <img src="assets/img/home/Why-Choose-Us1.jpg" alt="Best Compliance management Software">
                    <div class="images">
                        <img src="assets/img/home/Why-Choose-Us.jpg" alt="Best Compliance management Software">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Services Area -->
<div class="services-area choose-area pt-40 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Key Features</span>
                    <h2>We are constantly working towards the highest level of Compliance services, to make you compliant with our effective tools</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>CiB offers wide compliance coverage across industry sectors. Its inbuilt workflow management aided with our expertise will help companies in complying with Regulations and directives.</p>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a >Dashboard</a></h3>
                    <p>Get a comprehensive view on single screen, know the status of various compliances and audit activities, evaluate vital reports in the form of charts, graphs, data grids.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card2">
                    <a  class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a >Workroom</a></h3>
                    <p>Now assign, monitor, review the progress, ensure Resource Engagement with Role-based access to stakeholders, resources thru encrypted users id and passwords.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card3">
                    <a class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a >Dataroom</a></h3>
                    <p>Data security is no longer a concern. CiB as a compliance management system has a safe and secure data-sharing facility with integrated document management, access based permanent repository.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card4">
                    <a class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a >E-Audit</a></h3>
                    <p>Create and publish Audit plans, track the same with our Auditing Management. Quickly access the previous year's data from repository which has a customizable standard file folder structure which eliminates resource dependency. Peer review feature.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card5">
                    <a class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a >Compliance Calendar</a></h3>
                    <p>Receive timely updates on compliances with our automated alerts, triggers & notifications on our compliance management software. View all upcoming compliance events and their due dates. You can set the frequency for sending SMS, email reminders.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card6">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Notification & Alerts</a></h3>
                    <p>Stay updated at all times with the notifications and alerts feature. Integration with regulatory sites ensures quick notifications that can be shared with all concerned.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card7">
                    <a r class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a >Chatroom</a></h3>
                    <p>Integrated chatroom facility with history ensures a quick reference point, Chatroom also enables you to send & receive messages, share documents. </p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card8">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Coverage</a></h3>
                    <p>CiB, being a compliance management system covers all applicable laws such as Direct & Indirect Tax, Labour Laws, State, and federal compliances, Corporate Laws.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-card9">
                    <a class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a >Financial Analytics</a></h3>
                    <p>CiB is integrated with financial accounting software. You can now view/access all real time financial reports through this feature.</p>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>

        
    </div>
</div>
<!-- Services Area End -->

<!-- Benefits -->
<div class=" pt-50 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>What are the benefits of having CiB, a Compliance Management Software for your Business?</h2>
                    <ul>
                        <li>CiB provides extensive compliance management covering all business areas. Companies will be able to comply with regulations and guidelines. Thanks to its built-in workflow management.</li>
                        <li>Our compliance management system adds value with timely knowledge on compliance readiness at each organisational level, you may reduce the risk of compliance violations, fines, and reputational harm.</li>
                        <li>A consolidated, real-time view of the organization's compliance status with a complete automated compliance management system will enhance business performance and decision-making all around the year. </li>
                        <li>With our compliance management software, you can benefit through standardised and streamlined procedures for control documentation, assessments, and testing, proactively identifying potential compliance concerns.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Benefits -->

<!-- Benefits -->
<div class="choose-area pt-50 pb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>How does CiB Compliance Management Software help You?</h2>
                    <p><b>1. Maintain Your Regulatory Intelligence Skills</b></p>
                    <p>By integrating the CiB, Compliance Management Software, with dependable and authoritative regulatory content sources, you can capture, store, and monitor regulations. Connect regulatory updates to risks, controls, and policies, and use automatic notifications and alerts to keep up to date.</p>
                    <p><b>2. Designing a compliance management environment and processes for efficient operations</b></p>
                    <p>Make an internal control hierarchy that is logically organised and includes processes, assets, risks, controls, and control activities, as well as the proper connections between these data pieces. Maintain corresponding policies, processes, reporting specifications, filing schedules, and templates for relevant regulations with CiB, a compliance management tool.</p>
                    <p><b>3. Ease with Auditing Management</b></p>
                    <p>CiB, compliance management software, now comes along with an audit management tool that considerably reduces the time and work required for an organisation to successfully complete an external audit or perform an internal audit. It accomplishes this by streamlining the process by automating audit lifecycle operations.</p>
                    <p>A document control system can interact with audit software, allowing users to locate and retrieve any required audit programme material. Companies use compliance management tools to make sure audit directives are followed. Additionally, it assists in streamlining and organising the workflow and teamwork involved in creating audits.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Benefits -->

<!-- Client Area -->
<div class="client-area pt-40 pb-40">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="client-title">
                    <div class="section-title">
                        <span>Clients Review</span>
                        <h2>Our customers are the true heroes of our story.</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="client-text">
                    <p>Our clients praise us for our personable service, expert knowledge, on-time delivery. Here is what a few of them had to say</p>
                </div>
            </div>    
                <nav class="client_tab">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist" style="width: 100%;">
                        <button class="nav-link client_nav_first active"  id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">CA</button>
                        <button class="nav-link client_nav_second" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">CS</button>
                        <button class="nav-link client_nav_third" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">MSME</button>
                        <button class="nav-link client_nav_four" id="nav-other-tab" data-bs-toggle="tab" data-bs-target="#nav-other" type="button" role="tab" aria-controls="nav-other" aria-selected="false">PRO Firms</button>
                    </div>
              </nav>
              <div class="tab-content" id="nav-tabContent" >
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    
                    <div class="client-area-two ptb-40">
                        <div class="container">
                            
                            <div class="client-slider owl-carousel owl-theme pt-45">
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Vishnu_daya.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>CA Venkatesh Kamath SV</h3>
                                                <span>Partner</span><br>
                                                <span>Vishnu Daya & Co LLP</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>We are among the first users of CiB, the E- Audit Functionality has helped immensely in ensuring timely completion of Audits. Recently added PEER Review feature is a boon for all practising CA's.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/M-Krishna-&-Co-LLP.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>CA Krishna Padakannaya</h3>
                                                <span>Proprietor</span><br>
                                                <span>M Krishna & Co</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>CiB's E-Audit and workflow management have worked wonders for our team. Faster communication and secure data sharing leave nothing to worry.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12 offset-lg-0 offset-md-3">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Manish-Ghia-&-Associates.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Partner</h3>
                                                <span>&nbsp;</span><br>
                                                <span>CA Firm in Mumbai</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>Best in class Audit Management tool, has helped a lot in ensuring timely completion of audits with reduced effort. The PEER review feature is a must have.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12 offset-lg-0 offset-md-3">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Pincha-&-Co.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Ashok Pincha</h3>
                                                <span>Proprietor</span><br>
                                                <span>Pincha & Co</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>Audit Plan publishing, assigning and tracking make Audits super smooth and transparent. Would definitely recommend to fellow practitioners.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12 offset-lg-0 offset-md-3">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Vasanth-&-Co.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Partner</h3>
                                                <span>&nbsp;</span><br>
                                                <span>CA Firm in Bangalore</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>E-Audit thru CiB has superb workflow, Resource engagement, Client data sharing features that has resulted in 50% reduction in time required for Audit. I would recommend to all the practising Chartered Accountants.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12 offset-lg-0 offset-md-3">
                                        <div class="client-item">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Rakchamps-&-Co.-LLP.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Partner</h3>
                                                <span>&nbsp;</span><br>
                                                <span>CA Firm in Bangalore</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <p>E-Audit feature of CiB has resulted into reduced client visits, considerably reduced time and efforts for us. Happy to continue using CiB for all our Compliance management and Audit management needs.</p>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <div class="client-area-two ptb-40">
                        <div class="container">
                            
                            <div class="client-slider owl-carousel owl-theme pt-45">
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item"  (click)="alterDescriptionText()">
                                            <!--<div class="client-img">
                                                <img src="assets/img/client/client-img1.png" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>&nbsp;</h3>
                                                <span>CS Firm in Mumbai</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <!--<p>Great workflow and excellent resource engagement with continuous tracking of ROC compliance tasks. The ease of use and definition of services and subservices are top notch, all our regular compliances are taken care. We have experienced increased efficiency with 40% reduction in time and effort.</p>-->
                                                <p [ngClass]="{'show-less': showShortDesciption}"> Great workflow and excellent resource engagement with continuous tracking of ROC compliance tasks. The ease of use and definition of services and subservices are top notch, all our regular compliances are taken care. We have experienced increased efficiency with 40% reduction in time and effort.</p><span class="read-more">{{showShortDesciption ? 'Read More': 'Read Less' }}</span>
                                                <br><div class="clearfix"></div>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <div class="client-area-two ptb-40">
                        <div class="container">
                            
                            <div class="client-slider owl-carousel owl-theme pt-45">
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item" (click)="alterDescriptionText1()">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/KIC-Ventures.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>&nbsp;</h3>
                                                <span>CFO</span><br>
                                                <span>VC Firm in USA</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <!--<p>Number of entities within the group having operations in various jurisdictions, we always struggled with compliance management. The intelligent Dashboard, the workflow and ease of use, automated alerts and reminders are excellent features provided by CiB that has helped us immensely with our Compliance management and resource engagement. Definitely a must have tool for organisations.</p>-->
                                                <p [ngClass]="{'show-less': showShortDesciption1}"> Number of entities within the group having operations in various jurisdictions, we always struggled with compliance management. The intelligent Dashboard, the workflow and ease of use, automated alerts and reminders are excellent features provided by CiB that has helped us immensely with our Compliance management and resource engagement. Definitely a must have tool for organisations.</p><span class="read-more">{{showShortDesciption ? 'Read More': 'Read Less' }}</span>
                                                <br><div class="clearfix"></div>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item" (click)="alterDescriptionText2()">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Diligent-Global.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Atul Siddam</h3>
                                                <span>Promoter</span><br>
                                                <span>Diligent Group</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <!--<p>Having presence in multiple jurisdictions was giving us a tough time with managing the global compliances. With CiB, we are able to achieve complete control. Its Dashboard gives quick birds eye view of all the compliance events. Now I am able to give more time to growing the business.</p>-->
                                                <p [ngClass]="{'show-less': showShortDesciption2}"> Having presence in multiple jurisdictions was giving us a tough time with managing the global compliances. With CiB, we are able to achieve complete control. Its Dashboard gives quick birds eye view of all the compliance events. Now I am able to give more time to growing the business.</p><span class="read-more">{{showShortDesciption ? 'Read More': 'Read Less' }}</span>
                                                <br><div class="clearfix"></div>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
                    <div class="client-area-two ptb-40">
                        <div class="container">
                            
                            <div class="client-slider owl-carousel owl-theme pt-45">
                                <div class="client-card">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="client-item" (click)="alterDescriptionText3()">
                                            <!--<div class="client-img">
                                                <img src="assets/img/reviews/Dubai.jpg" alt="Images">
                                            </div>-->
                        
                                            <div class="content">
                                                <h3>Partner</h3>
                                                <span>&nbsp;</span><br>
                                                <span>A Leading PRO Firm in Dubai</span>
                                                <ul>
                                                    <li>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                        <i class='bx bxs-star'></i>
                                                    </li>
                                                </ul>
                                                <!--<p>Easy client onboarding, faster communication, automated alerts & reminders have helped us ensure that we never miss a renewal timeline. CiB has resulted in increased efficiency, more happy clients. Now we are always in control. Every PRO firm should use CiB</p>-->
                                                <p [ngClass]="{'show-less': showShortDesciption3}"> Easy client onboarding, faster communication, automated alerts & reminders have helped us ensure that we never miss a renewal timeline. CiB has resulted in increased efficiency, more happy clients. Now we are always in control. Every PRO firm should use CiB.</p><span class="read-more">{{showShortDesciption ? 'Read More': 'Read Less' }}</span>
                                                <br><div class="clearfix"></div>
                                                <i class="flaticon-quote quote"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
              </div>

             
            
        </div>

        
    </div>
</div>
<!-- Client Area End -->

<!--<div class="client-area-two ptb-40">
    <div class="container">
        
        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <img src="assets/img/client/avanze_group.PNG" alt="Images">
            </div>

            <div class="client-card">
                <img src="assets/img/client/cazaayan.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/centric.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/compito.PNG" alt="Images">
            </div>
            <div class="client-card">
                <img src="assets/img/client/diligent.PNG" alt="Images">
            </div>
        </div>
    </div>
</div>-->

<!-- Team Area -->
<div class="team-area pt-40 pb-40">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Team</span>
            <h2>Great things in business are never done by one person, it is Teamwork always.</h2>
        </div>
        <div class="team-slider owl-carousel owl-theme pt-45">
            <div class="client-card" >
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Sameer-P.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <!--<li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>--> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>SAMEER PADDALWAR</h3>
                        <span>DIRECTOR & CO-FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/sameer-paddalwar" class="view-btn" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">    
                <div class="team-card">
                    <div class="team-card">
                        <div class="team-img">
                            <img src="assets/img/team/Ganesh-P.jpg" alt="Images">
                            
                            <div class="social-icon">
                                <ul class="social-link">
                                    <li><a href="https://www.linkedin.com/in/ganesh-padakannaya-2b739516?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B5ELVyaWRS%2FWsJUzkTHoBrA%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                </ul>
                            </div>
                        </div>
    
                        <div class="content">
                            <h3>GANESH PADAKANNAYA</h3>
                            <span>DIRECTOR & CO FOUNDER</span>
                            <div class="teams-view-btn">
                                <a routerLink="/ganesh-padakannya" class="view-btn">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Pradeep-KV.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/pradeep-kumar-v-b5188a1a0?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BB3upA3W3SIiI4t%2F3uhZkfw%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>PRADEEP KUMAR V</h3>
                        <span>DIRECTOR & CO-FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/pradeep-kumar-v" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Vinod-K.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/vinod-kainikat-196a604?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BKnn8PI%2BVRGm3Mb%2BCyy62rw%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>VINOD KAINIKAT</h3>
                        <span>DIRECTOR & CO-FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/vinod-kainikat" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Ramu-R.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/ramuradhakrishnan?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BT%2FsP3LYkQgOLEjjR%2F16SAA%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>RAMU RADHAKRISHNAN</h3>
                        <span>DIRECTOR & CPO</span>
                        <div class="teams-view-btn">
                            <a routerLink="/ramu-radhakrishnan" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Amit-M.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/amit-mulgund-ca-icai-3084a448/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>AMIT MULGUND</h3>
                        <span>MANAGING DIRECTOR - EMEA</span>
                        <div class="teams-view-btn">
                            <a routerLink="/amit-mulgund" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Amar-T.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/amartumplwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BKevxZbSgSdSTO4E4qLkbjw%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>AMAR TUMPLWAR</h3>
                        <span>V P – BUSINESS DEVELOPMENT</span>
                        <div class="teams-view-btn">
                            <a routerLink="/amar-tumplwar" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="client-card">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Ujjal-Mukherjee.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/ujjal-mukherjee-3b8245165?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B563%2BeNJxQ2mSGIOEYNfoxg%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>UJJAL MUKHERJEE</h3>
                        <span>PRODUCT MANAGER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/ujjal-mukherjee" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!--<div class="row pt-45 pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Sameer-P.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.linkedin.com/in/sameerpaddalwar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BTPCwJs6RTJyL0byzh6%2Bi0Q%3D%3D" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>SAMEER PADDALWAR</h3>
                        <span>DIRECTOR & CO-FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Ganesh-P.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>GANESH PADAKANNAYA</h3>
                        <span>DIRECTOR & CO FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/Pradeep-KV.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>PRADEEP KUMAR V</h3>
                        <span>DIRECTOR & CO-FOUNDER</span>
                        <div class="teams-view-btn">
                            <a routerLink="/" class="view-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>-->
    </div>
</div>
<!-- Team Area End -->



<!-- Client Area -->
<div class="blogs-area blog-area-outer pt-40">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
                <div class="client-title">
                    <div class="section-title">
                        <span>Our Blogs</span>
                        <h2>Customer service is a continuous process and Customer Satisfaction is what we aim.</h2>
                    </div>
                </div>
            </div>

            
        </div>

        <div class="blog-area-two  ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-6" *ngFor="let latestblog of latestblogList;  let i =index">
                                <div class="blog-card">
                                    <a (click)="blogDetails(latestblog?.blog_id,latestblog?.blog_name)" style="cursor: pointer;" ><img src="{{latestblog?.blog_image}}" alt="Images"></a>
                                    <div class="content">
                                        <h3><a (click)="blogDetails(latestblog?.blog_id,latestblog?.blog_name)" style="cursor: pointer;" >{{latestblog?.blog_name | slice:0:44  }}...</a></h3>
                                        <!--<ul>
                                            <li>By <a routerLink="/blog-1">{{latestblog?.created_by}} </a> /</li>
                                            <li><span>20</span> COMMENTS /</li>
                                            <li><span>28</span> VIEWS</li>
                                        </ul>-->
                                        <p>{{latestblog?.short_desc  | slice:0:120 }}...</p>
                                        <a (click)="blogDetails(latestblog?.blog_id,latestblog?.blog_name)" style="cursor: pointer;" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                    </div>
                                </div>
                            </div>
        
                            <!--<div class="col-lg-4 col-md-6">
                                <div class="blog-card">
                                    <a routerLink="/blog-details"><img src="assets/img/blog/blog-img7.jpg" alt="Images"></a>
                                    <div class="content">
                                        <h3><a routerLink="/blog-details">A Good Business Depends on Good Planning</a></h3>
                                        <ul>
                                            <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                            <li><span>24</span> COMMENTS /</li>
                                            <li><span>20</span> VIEWS</li>
                                        </ul>
                                        <p>Audit plan tracker with Audit Trail. Previous years data in repository. Standard Audit Templates, view of work in progress, completed activities. Independent access to Auditors, can restrict change once activity completed. </p>
                                        <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-4 col-md-6">
                                <div class="blog-card">
                                    <a routerLink="/blog-details"><img src="assets/img/blog/blog-img3.jpg" alt="Images"></a>
                                    <div class="content">
                                        <h3><a routerLink="/blog-details">Success Depends on Strategy and Plan</a></h3>
                                        <ul>
                                            <li>By <a routerLink="/blog-1">ADMIN </a> /</li>
                                            <li><span>20</span> COMMENTS /</li>
                                            <li><span>14</span> VIEWS</li>
                                        </ul>
                                        <p>Audit plan tracker with Audit Trail. Previous years data in repository. Standard Audit Templates, view of work in progress, completed activities. Independent access to Auditors, can restrict change once activity completed. </p>
                                        <a routerLink="/blog-details" class="blog-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                    </div>
                                </div>
                            </div>-->
        
                            
                        </div>
                        <div class="service-view-btn text-center">
                            <a (click)="getBlogList(0,0)" style="cursor: pointer;"  class="view-btn">View More</a>
                        </div>
                    </div>
        
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->





<!-- Contact Area -->
<div  #target class="contact-area pb-70 " id="enq-form">
    <div class="container" >
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>For More Info & Free Demo</h2>
                            <p>Manage compliance and much more with the CiB. Schedule a free demo NOW!</p>
                        </div>

                        <form [formGroup]="contact" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" [ngClass]="{'is-invalid': submitted && f.name.errors }" class="form-control" placeholder="Name*">
                                        <div *ngIf="submitted && f.name.errors" class="text-danger small err_msg">
                                            <div *ngIf="f.name.errors?.required">Name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" formControlName="email_id" [ngClass]="{'is-invalid': submitted && f.email_id.errors }" class="form-control" required placeholder="Email*">
                                        <div *ngIf="submitted && f.email_id.errors" class="text-danger small err_msg"
                                            [ngClass]="{'is-invalid': submitted && f.email_id.errors }">
                                            <div *ngIf="f.email_id.errors?.required">Email is required</div>
                                            <div *ngIf="f.email_id.errors?.email">Please enter vaild email Id.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" pattern="\d*" maxlength="15" formControlName="mobile" class="form-control" placeholder="Mobile Number*">
                                        <div *ngIf="submitted && f.mobile.errors" class="text-danger small err_msg"
                                            [ngClass]="{'is-invalid': submitted && f.mobile.errors }">
                                            <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                            <div *ngIf="f.mobile.errors?.pattern">Invalid mobile number</div>
                                        </div>    
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                       
                                        <input type="text" formControlName="organization" [ngClass]="{'is-invalid': submitted && f.organization.errors }" class="form-control" placeholder="Organization Name*">
                                        <div *ngIf="submitted && f.organization.errors" class="text-danger small err_msg">
                                            <div *ngIf="f.organization.errors?.required">Organization is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="message" cols="30" rows="4"  placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 ">
                                    <mat-form-field style="background-color: #f8f9fb;border: 1px solid #ebebeb;border-radius: 0.25rem;height: 61px;
                                    line-height: 2;
                                    padding-left: 15px;">
                                        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="schedule_dt" placeholder="Choose Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker ></mat-datepicker>
                                        <mat-error *ngIf="f.schedule_dt.hasError('required')"> Schedule Date is required</mat-error>
                                      </mat-form-field>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Choose Time*</mat-label>
                                    
                                        <!-- The timepicker input -->
                                        <input matTimepicker #t="matTimepicker"  [strict]="false" id="timepicker-example" mode="24h"
                                        formControlName="schedule_time" >
                                        <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
                                        <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon>
                                        <mat-error *ngIf="f.schedule_time.hasError('required')"> Schedule Time is required</mat-error>
                                      </mat-form-field>
                                </div>


                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <mat-checkbox formControlName="agreeTerms">
                                            I agree to all <a routerLink="/privacy-policy">terms and conditions</a>
                                        </mat-checkbox>
                                        
                                        <div *ngIf="submitted && f.agreeTerms.errors" class="text-danger small err_msg">
                                            <div *ngIf="f.agreeTerms.errors?.required">You must agree to the terms and conditions</div>
                                        </div>
                                    </div>
                                </div>
                                


                                <div class="col-lg-6 col-md-12 text-center">
                                    <button type="submit" class="default-btn contact-page-btn">Schedule Demo</button>
                                </div>
                                <!--<div class="col-lg-6 col-md-12 text-center">
                                    <a routerLink="/contacts" class="default-btn"  style="width: 100%;text-align: center;">Free Trial</a>
                                </div>-->
                            </div>
                        </form>
                        <div class="alert alert-success mt-4 successmsg" role="alert" *ngIf="resultTrue">
                            Thank You for showing interest. Our team would get back to you shortly.
                        </div>
                        <div class="alert alert-danger successmsg" role="alert" *ngIf="resultFalse">
                            Something went wrong please try again
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->